import { Pipe, PipeTransform } from '@angular/core';
import {
    isImage
} from './helpers/media-helpers';

@Pipe({
    name: 'isImageUrl',
})
export class IsImageUrlPipe implements PipeTransform {
    transform(url: string) {
        return isImage(url);
    }
}
