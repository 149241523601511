import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import { Cms } from '@impact/data';
import { TrackingService } from '../../utils/tracking/tracking.service';
import { SpotsUiModule } from '../../spots/spots-ui.module';

@Component({
    selector: 'app-not-found-page',
    template: `
        <main>
            <section class="page-grid-content">
                <app-spot-grid [grid]="data?.mainGrid"></app-spot-grid>
            </section>
        </main>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundPageComponent implements OnInit {
    @Input() data?: Cms.NotFoundPage;

    constructor(private trackingService: TrackingService) {}

    ngOnInit() {
        this.trackingService.track404();
    }
}

@NgModule({
    declarations: [NotFoundPageComponent],
    imports: [
        SpotsUiModule
    ]
})
class NotFoundPageModule { }

export const LazyComponent = NotFoundPageComponent;
