import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { Cms, IImage, TempAny } from '@impact/data';
import { StyleBreakpoints } from '../models/style-breakpoints';
import { MediaSliderService } from './media-slider.service';
import { IImageBreakpoints } from '../utils/responsive-image.component';

const imageBreakpoints: IImageBreakpoints[] = [
    {
        breakpoint: StyleBreakpoints.Implicit,
        width: 360,
        mode: 'crop',
    },
    {
        breakpoint: StyleBreakpoints.Xsmall,
        width: 460,
        mode: 'crop',
    },
    {
        breakpoint: StyleBreakpoints.Small,
        width: 768,
        mode: 'crop',
    },
    {
        breakpoint: StyleBreakpoints.Medium,
        width: 1024,
        mode: 'crop',
    },
    {
        breakpoint: StyleBreakpoints.Large,
        width: 1200,
        mode: 'crop',
    },
    {
        breakpoint: StyleBreakpoints.Xlarge,
        width: 1880,
        mode: 'crop',
    },
];

@Component({
    selector: 'app-media-item',
    template: `
        <app-responsive-image
            class="media-slider-content__image"
            *ngIf="item?.type.toLowerCase() === 'image'"
            [breakpoints]="imageBreakpoints"
            [image]="item"
            [lazyLoad]="lazyLoad"
        ></app-responsive-image>

        <app-video-player
            *ngIf="item?.type === 'umbracoMediaVideo'"
            [fallbackImage]="videoFallbackImage"
            [preload]="lazyLoad ? 'metadata' : 'auto'"
            [video]="item?.src | resolveMediaUrl"
            [vimeoId]="vimeoId"
        ></app-video-player>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaItemComponent {
    @Input() item?: TempAny;
    @Input() vimeoId?: string;
    @Input() videoFallbackImage?: Cms.Media;
    @Input() lazyLoad = true;

    imageBreakpoints = imageBreakpoints;

    constructor(public mediaSliderService: MediaSliderService) {}
}
