import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Cms } from '@impact/data';

@Component({
    selector: 'app-carousel-spot',
    template: `
        <app-media-slider-new
            [sliderItems]="data.items"
            [lazyLoad]="lazyLoad"
            [preLoadRTEImages]="true">
        </app-media-slider-new>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselSpotComponent {
    @Input() data: Cms.CarouselSpot;
    @Input() lazyLoad = true;
}
