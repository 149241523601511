import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { MediaSliderModule } from '../media-slider/media-slider.module';
import { CarouselSpotComponent } from './carousel-spot/carousel-spot.component';
import { SpotGridComponent } from './spot-grid/spot-grid.component';
import { SpotRendererComponent } from './spot-renderer/spot-renderer.component';

@NgModule({
    declarations: [
        CarouselSpotComponent,
        SpotGridComponent,
        SpotRendererComponent,
    ],
    imports: [
        CommonModule,
        MediaSliderModule,
        SwiperModule,
    ],
    exports: [
        CarouselSpotComponent,
        SpotGridComponent,
        SpotRendererComponent
    ],
})
export class SpotsUiModule {}
