import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { ContentService } from '../content/content.service';

@Injectable({
    providedIn: 'root',
})
export class TranslateLoaderService implements TranslateLoader {
    constructor(private contentService: ContentService) {}

    getTranslation() {
        return this.contentService.getTranslations();
    }
}
