import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cms } from '@impact/data';
import { EMPTY, Observable, of } from 'rxjs';
import { shareReplay, take, tap } from 'rxjs/operators';
import { ContentService } from '../content/content.service';
import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';

const twoMinutesMs = 120000;

@Injectable({
    providedIn: 'root',
})
export class GlobalVehicleDataService {
    private readonly isServer: boolean;
    private readonly vehicleMakes$ = this.contentService
        .getVehicleMakes()
        .pipe(shareReplay({ bufferSize: 1, refCount: false }));

    private vehicleModelAndVariantsStore = new Map<
        string,
        {
            item: Cms.VehicleModelVariantsDto;
            timeout: number;
        }
    >();

    constructor(
        private contentService: ContentService,
        private http: HttpClient,
        featureDetectionService: FeatureDetectionService
    ) {
        this.isServer = featureDetectionService.isServer();
    }

    getVehicleMakes() {
        return this.vehicleMakes$.pipe(take(1));
    }

    getVehicleModelAndVariants(modelId: string) {
        return this.getAndStoreVehicleModelAndVariants(modelId);
    }

    getVehicleModels(makeId: string | undefined) {
        return makeId ? this.contentService.getVehicleModels(makeId) : EMPTY;
    }

    // Vehicle model card list
    public getVehicleModelCardList(id: string): Observable<any> {
        if (!id) {
            console.warn('No model id provided for vehicleModelCardListById');
            return EMPTY;
        }

        return this.http.get(`/api/vehicleData/vehicleModelCardListById`, {
            params: { id },
        });
    }

    private getAndStoreVehicleModelAndVariants(modelId: string) {
        const request$ =
            this.contentService.getVehicleModelAndVariants(modelId);
        if (this.isServer) return request$;

        const cacheItem = this.vehicleModelAndVariantsStore.get(modelId);
        if (cacheItem) {
            console.log(`[GlobalVehicleDataService] Serving model and variants for model ID "${modelId}" from cache. ${cacheItem.item}`);
            return of(cacheItem.item);
        }

        return request$.pipe(
            tap((r) => {
                this.vehicleModelAndVariantsStore.set(modelId, {
                    item: r,
                    timeout: window.setTimeout(
                        () => this.vehicleModelAndVariantsStore.delete(modelId),
                        twoMinutesMs
                    ),
                });
            })
        );
    }
}
