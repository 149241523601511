import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Environment, ENVIRONMENT } from '../environment/environment';
import {
    ImageProcessorOptions,
    resolveMediaUrl,
} from './helpers/media-helpers';

@Pipe({
    name: 'resolveMediaUrl',
})
export class ResolveMediaUrlPipe implements PipeTransform {
    constructor(@Inject(ENVIRONMENT) private environment: Environment) {}

    transform(url: string | undefined, options?: ImageProcessorOptions) {
        return resolveMediaUrl(url ?? '' , this.environment, options);
    }
}
