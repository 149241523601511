import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LinkModule } from '../../link/link.module';
import { MegaNavigationSpotComponent } from './mega-navigation-spot.component';
import { UtilsModule } from '../../utils/utils.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forChild(),
        LinkModule,
        UtilsModule
    ],
    exports: [
        MegaNavigationSpotComponent
    ],
    declarations: [
        MegaNavigationSpotComponent
    ],
    providers: [],
})
export class MegaNavigationSpotModule { }
