import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationStart, Router, Scroll } from '@angular/router';
import { delay, filter, take, tap } from 'rxjs/operators';

import { PageBusyService } from '../pages/page-busy.service';

@Injectable({
    providedIn: 'root',
})
export class ScrollRestorationService {
    private scrollHistory: Map<string, [number, number]> = new Map();

    private lastNavigationTrigger:
        | 'imperative'
        | 'popstate'
        | 'hashchange'
        | undefined = undefined;

    constructor(
        private pageBusyService: PageBusyService,
        private router: Router,
        private viewportScroller: ViewportScroller
    ) {}

    public initScrollRestorationHelper() {
        this.router.events
            .pipe(
                tap((event) => {
                    if (event instanceof NavigationStart) {
                        this.lastNavigationTrigger = event.navigationTrigger;
                        if (event.navigationTrigger === 'imperative') {
                            this.scrollHistory.set(
                                this.router.url,
                                this.viewportScroller.getScrollPosition()
                            );
                        }
                    }
                }),
                delay(0)
            )
            .subscribe((event) => {
                if (event instanceof Scroll) {
                    this.handleScrollRestoration(
                        event,
                        this.scrollHistory.get(this.router.url),
                        this.lastNavigationTrigger === 'popstate'
                    );
                }
            });
    }

    // ScrollPositionRestoration: 'enabled', is currently not working as intended:
    // https://github.com/angular/angular/issues/24547
    // This is a temporarily solution for that, this should be removed when the issue is solved
    // Though ensure popstate restore (the first case) is handled also
    private handleScrollRestoration(
        scrollEvent: Scroll,
        lastScrollPosition?: [number, number],
        wasPopstate?: boolean
    ): void {
        // if position is undefined and not null, this means angular lost track of the scroll position.
        // This happens if you replace the url with location.replaceState, and goes back by popstate
        // In that case to ensure that we restore the correct scrollstate, we restore last scroll position
        // which we store here in the scroll service
        const { position, anchor } = scrollEvent;
        if (position === undefined && wasPopstate && lastScrollPosition) {
            this.pageBusyService.isPageBusy$
                .pipe(
                    filter((x) => !x),
                    take(1)
                )
                .subscribe(() => {
                    setTimeout(() => {
                        this.viewportScroller.scrollToPosition(
                            lastScrollPosition
                        );
                    }, 200);
                });
        } else if (position) {
            this.viewportScroller.scrollToPosition(position);
        } else if (anchor) {
            this.viewportScroller.scrollToAnchor(anchor);
        } else {
            this.viewportScroller.scrollToPosition([0, 0]);
        }
    }
}
