import { Component } from '@angular/core';
import { MegaNavigationService } from '../../navigation/mega-navigation/mega-navigation.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-site-backdrop',
    template: `
        <div
            class="site-backdrop"
            [class.is-active]="showMegaNavigation$ | async"
            (click)="hideBackdrop()"
        ></div>
    `,
})
export class SiteBackdropComponent {
    showMegaNavigation$ = this.megaNavigationService.showMegaNavigation$.pipe(
        map((m) => m.isActive)
    );

    constructor(private megaNavigationService: MegaNavigationService) {}

    hideBackdrop() {
        this.megaNavigationService.showMegaNavigation(false);
    }
}
