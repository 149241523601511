import { Inject, Injectable } from '@angular/core';
import { Cms } from '@impact/data';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { Environment, ENVIRONMENT } from '../environment/environment';
import { NavigationService } from '../navigation/navigation.service';
import { STORAGE_SERVICE_KEYS, StorageService } from './storage.service';
import { ContentService } from '../content/content.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationBarService {
    private updateNotifications$ = new BehaviorSubject<void>(undefined);

    notifications$ = combineLatest([
        this.contentService.getSiteNotifications(),
        this.navigationService.currentUrl$,
        this.updateNotifications$,
    ]).pipe(
        map(([notifications, currentUrl]) =>
            this.filterNotifications(notifications, currentUrl)
        ),
        shareReplay({ bufferSize: 1, refCount: false })
    );

    constructor(
        private readonly contentService: ContentService,
        private navigationService: NavigationService,
        private storageService: StorageService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    closeNotification(notificationId: string): void {
        const closedNotifications = this.getClosedNotificationsFromStorage();

        if (!closedNotifications.includes(notificationId)) {
            closedNotifications.push(notificationId);

            this.storageService.setItem(
                STORAGE_SERVICE_KEYS.NOTIFICATIONS,
                JSON.stringify(closedNotifications)
            );

            this.updateNotifications$.next();
        }
    }

    private filterNotifications(
        notifications: Cms.NotificationDto[],
        currentUrl: string
    ) {
        const closedIds = this.getClosedNotificationsFromStorage();

        return notifications.filter((n) => {
            if (closedIds.includes(n.id)) return false;

            return n.cascadeNotification
                ? currentUrl.startsWith(n.showOnPage.url)
                : currentUrl === n.showOnPage.url;
        });
    }

    private getClosedNotificationsFromStorage(): string[] {
        const storage = this.storageService.getItem(
            STORAGE_SERVICE_KEYS.NOTIFICATIONS
        );
        return storage ? JSON.parse(storage) : [];
    }
}
