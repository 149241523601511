import { AnimationEvent } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
} from '@angular/core';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
    mobileAnimation,
    MobileAnimationState,
} from '../../utils/animations/mobile-menu.animations';
import { NavigationService } from '../navigation.service';
import { IMobileMenu, MobileMenuTypes } from './mobile-menu';
import { MobileNavigationService } from './mobile-navigation.service';

@Component({
    selector: 'app-mobile-menu',
    template: `
        <aside
            class="mobile-menu"
            [@slideContent]="animationState"
            (@slideContent.done)="onAnimationDone($event)"
            *ngIf="mainNavigation$ | async as mainNavigation"
        >
            <nav role="navigation" aria-label="mobile navigation">
                <app-mobile-overlay-header
                    [showBack]="(isRoot$ | async) === false"
                    (back)="back()"
                    (closed)="closeMenu()"
                ></app-mobile-overlay-header>
                <div
                    class="mobile-menu__root-conte1nt mobile-menu__scroll-container"
                    *ngIf="(currentMenu$ | async) === undefined; else itemNavigation"
                >
                    <app-mobile-nav-list [navigationChildren]="mainNavigation" [heading]="'navigation.menu' | translate"></app-mobile-nav-list>
                    <app-mobile-meta-list></app-mobile-meta-list>
                </div>

                <ng-template #itemNavigation>
                    <ng-container *ngIf="currentMenu$ | async as currentMenu" [ngSwitch]="currentMenu.type">
                        <ng-container *ngIf="currentMenu.navigation">
                            <app-mobile-brand-overview-nav
                                *ngSwitchCase="mobileMenuTypes.BrandsOverview"
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-brand-overview-nav>

                            <app-mobile-new-cars-brand-models-nav
                                *ngSwitchCase="mobileMenuTypes.NewCarsBrandModels"
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-new-cars-brand-models-nav>

                            <app-mobile-used-cars-brands-models-nav
                                *ngSwitchCase="mobileMenuTypes.UsedCarsBrandModels"
                                [currentMenu]="currentMenu"
                            ></app-mobile-used-cars-brands-models-nav>

                            <app-mobile-articles-nav
                                *ngSwitchCase="mobileMenuTypes.Articles"
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-articles-nav>

                            <app-mobile-used-cars-nav
                                *ngSwitchCase="mobileMenuTypes.UsedCarsOptions"
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-used-cars-nav>

                            <app-mobile-default-nav
                                *ngSwitchDefault
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-default-nav>
                        </ng-container>
                    </ng-container>
                </ng-template>
            </nav>
        </aside>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [mobileAnimation],
})
export class MobileMenuComponent implements OnDestroy {
    mainNavigation$ = this.navigationService.mainNavigation$.pipe(
        map(navigation => navigation.filter(n => !n.hideInMenu && n.includeInNavigation)),
    );

    currentMenu$: Observable<IMobileMenu | undefined>;
    isRoot$: Observable<boolean>;
    animationState: MobileAnimationState = 'enter';
    mobileMenuTypes = MobileMenuTypes;
    
    private unsubscribe = new Subject<void>();

    constructor(
        private mobileNavigationService: MobileNavigationService,
        private overlayRef: ImpactOverlayRef,
        private cdr: ChangeDetectorRef,
        private navigationService: NavigationService,
    ) {
        this.currentMenu$ = this.mobileNavigationService.currentMenu$.pipe(
            tap((currentMenu) => {
                if (!currentMenu) {
                    this.animationState = 'enter';
                    this.cdr.markForCheck();
                }
            }),
            takeUntil(this.unsubscribe)
        );

        this.isRoot$ = this.currentMenu$.pipe(map((current) => !current));
    }

    back() {
        this.mobileNavigationService.back();
    }

    closeMenu() {
        this.animationState = 'leave';
    }

    onAnimationDone(event: AnimationEvent) {
        if (event.toState === 'leave') {
            this.overlayRef.close();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
