import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { TempAny } from '@impact/data';
import { Environment, ENVIRONMENT } from '../environment/environment';
import {
    ImageProcessorOptions,
    resolveMediaUrl,
} from './helpers/media-helpers';

export interface IImageBreakpoints {
    breakpoint: number;
    height?: number;
    mode?: ImageProcessorOptions['rmode'];
    width?: number;
}

@Component({
    selector: 'app-responsive-image',
    template: `
        <ng-container [ngSwitch]="isExternalImage">
            <img *ngSwitchCase="true"
                appImageLoadFadeIn
                [alt]="image.alt"
                [attr.loading]="lazyLoad ? 'lazy' : null"
                [height]="image.height"
                [ngClass]="cssClasses"
                [sizes]="sizes"
                [src]="image.src"
                [srcset]="srcSet"
                [width]="image.width"
            />
            <img *ngSwitchCase="false"
                appImageLoadFadeIn
                [alt]="image.alt"
                [attr.loading]="lazyLoad ? 'lazy' : null"
                [height]="image.height"
                [ngClass]="cssClasses"
                [sizes]="sizes"
                [src]="image.src | resolveMediaUrl: { format: 'webp', quality: 90 }"
                [srcset]="srcSet"
                [width]="image.width"
            />
        </ng-container>
    `,
})
export class ResponsiveImageComponent implements OnInit, OnChanges {
    @Input() image: TempAny;
    @Input() breakpoints: IImageBreakpoints[] = [];
    @Input() cssClasses = '';
    @Input() lazyLoad = true;

    sizes: string | undefined;
    srcSet: string | undefined;
    isExternalImage: boolean;

    constructor(@Inject(ENVIRONMENT) private environment: Environment) {}

    ngOnInit() {
        this.checkIfExternalImage();
        this.generateSrcset();
    }

    ngOnChanges() {
        this.checkIfExternalImage();
    }
    
    checkIfExternalImage() {
        this.isExternalImage = /^(http|https):\/\//.test(this.image.src);
    }


    generateSrcset(): void {
        if (!this.breakpoints?.length) return;

        const breakpointsByWidth = Array.from(this.breakpoints).sort(
            (a, b) => (a.width ?? a.breakpoint) - (b.width ?? b.breakpoint)
        );

        this.sizes = this.breakpoints.reduce((a, v, i) => {
            const isLast = i === this.breakpoints.length - 1;

            if (a) {
                a += ', ';
            }

            a += isLast
                ? `${v.width ?? v.breakpoint}px`
                : `(max-width: ${v.breakpoint}px) ${v.width ?? v.breakpoint}px`;

            return a;
        }, '');

        this.srcSet = breakpointsByWidth.reduce((a, v) => {
            let imageSrc;
            if (this.isExternalImage) {
                imageSrc = this.image.src;
            } else {
                imageSrc = resolveMediaUrl(this.image.src, this.environment, {
                    format: 'webp',
                    rmode: v.mode,
                    height: v.height,
                    quality: 90,
                    width: v.width,
                });
            }

            if (a) {
                a += ', ';
            }

            a += `${imageSrc} ${v.width ?? v.breakpoint}w`;

            return a;
        }, '');
    }
}
