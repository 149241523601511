import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Cms, TempAny } from '@impact/data';
import { getColorContrast } from '../../core/helpers';
import { ContentGrid, SpotRow } from '../spot-grid-types';

const baseClassName = 'spot-grid__row--';

export enum GridContext {
    PDP = 'pdp'
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    selector: 'app-spot-grid',
    templateUrl: './spot-grid.component.html',
})
export class SpotGridComponent implements OnInit {
    rows: SpotRow[] = [];

    @Input() grid: TempAny;
    @Input() context?: GridContext;
    @HostBinding('class.no-padding') get noPaddingClass(): boolean {
        return this.context === GridContext.PDP;
    }

    ngOnInit() {
        if (this.grid?.items) {
            this.resolveRows();
        }
    }

    resolveRows() {
        this.rows = (this.grid as ContentGrid).items.map((item) => {
            const color = item.settings.rowBackgroundColor ? item.settings.rowBackgroundColor : item.settings.themeBackgroundColor;

            const useLightForeground = getColorContrast(color) === 'light-contrast';

            return {
                classNames: this.resolveClassNames(
                    item.settings,
                    useLightForeground
                ),
                color,
                id: item.settings.pageNavigationAnchorId,
                spots: item.areas[0].items,
            };
        });
    }

    private resolveClassNames(
        settings: Cms.GridLayoutSettings,
        useLightForeground: boolean
    ) {
        const classNames: string[] = [];

        if (settings.isNarrowRow) {
            classNames.push(baseClassName + 'is-narrow');
        }

        if (settings.noSpacing) {
            classNames.push(baseClassName + 'no-spacing');
        }

        if (settings.verticalSpacing === 'Above') {
            classNames.push(baseClassName + 'spacing-above');
        }

        if (settings.verticalSpacing === 'Above and below') {
            classNames.push(baseClassName + 'spacing-above-and-below');
        }

        if (settings.verticalSpacing === 'Below') {
            classNames.push(baseClassName + 'spacing-below');
        }

        if (settings.themeBackgroundColor) {
            classNames.push(baseClassName + 'background-color');
        }

        if (useLightForeground) {
            classNames.push(baseClassName + 'light-foreground');
        }

        return classNames;
    }
}
