import { Cms, DateObj, IHoursOfBusiness } from '@impact/data';

export function leftFillNum(num: number | string, targetLength: number) {
    return typeof String.prototype.padStart !== undefined ? num.toString().padStart(targetLength, '0') : `0${num}`.slice(-2);
}

// Remove trailing slash from string
export function removeTrailingSlash(string: string): string {
    return string.replace(/\/$/, '');
}

// Get URL parameters from string
export function getStringParameters(urlString: string): any {

    if (urlString.indexOf('?') === -1) {
        return null;
    }

    const paramsObject: any = {};
    const query = urlString.split('?').pop();
    const stringParams = query ? query.split('&') : [];

    for (const param of stringParams) {
        const pair = param.split('=');
        paramsObject[pair[0]] = decodeURIComponent(pair[1]);
    }

    return paramsObject;
}

// Compare objects to see if they are equal
export function objectsAreEqual(a: any, b: any): boolean {
    // Get the keys of both objects
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    // Check if number of keys match
    if (aKeys.length !== bKeys.length) {
        return false;
    }

    // Check each key recursively
    for (const key of aKeys) {
        const aValue = a[key];
        const bValue = b[key];
        
        // If values are objects, recursively compare
        if (typeof aValue === 'object' && typeof bValue === 'object') {
            if (!objectsAreEqual(aValue, bValue)) {
                return false;
            }
        } else if (aValue !== bValue) { // Compare primitive values
            return false;
        }
    }

    return true;
}


// Clones an array or object (anything that is json compatible)
export function cloneObjectArray(object: any): any {
    return JSON.parse(JSON.stringify(object));
}

export function sortByKey(array: any[], keyToSortBy: string, descending: boolean = true): any[] {
    return array.slice().sort((a, b) => {
        const keyA = a[keyToSortBy];
        const keyB = b[keyToSortBy];
    
        // Adjust this comparison based on your sorting criteria
        if (descending) {
            if (keyA > keyB) {
                return -1;
            } else if (keyA < keyB) {
                return 1;
            } else {
                return 0;
            }
        } else {
            if (keyA < keyB) {
                return -1;
            } else if (keyA > keyB) {
                return 1;
            } else {
                return 0;
            }
        }
    });
}

export function sortObjectArrayByDateProperty(array: any[], datePropertyName: string, ascending: boolean = true): any[] {
    if (ascending) {
        return array.sort((a: any, b: any) => {
            const aProp = new Date(a[datePropertyName].split('T')[0]);
            const bProp = new Date(b[datePropertyName].split('T')[0]);
            const aTime = aProp.getTime();
            const bTime = bProp.getTime();

            return aTime - bTime;
        });
    } else {
        return array.sort((a: any, b: any) => {
            const aProp = new Date(a[datePropertyName].split('T')[0]);
            const bProp = new Date(b[datePropertyName].split('T')[0]);
            const aTime = aProp.getTime();
            const bTime = bProp.getTime();

            return bTime - aTime;
        });
    }
}

// Converts 7.5 into 7:30
export function convertNumToTime(number: number) {
    // Check sign of given number
    const sign = number >= 0 ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    const hour = Math.floor(number);
    let decpart = number - hour;

    const min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    let minute = Math.floor(decpart * 60) + '';

    // Add padding if need
    if (minute.length < 2) {
        minute = '0' + minute;
    }

    // Concate hours and minutes
    return hour + ':' + minute;
}

export function getTimeFromUTCDate(date: Date) {
    if (!date) return;
    const timeSlotMinutes = date?.getMinutes().toString();
    return `${date?.getHours()}:${timeSlotMinutes && timeSlotMinutes.length === 1 ? '0' + timeSlotMinutes : timeSlotMinutes}`;
}

export function getDateObjFromDate(date: Date): DateObj {
    return { year: date.getUTCFullYear(), month: date.getUTCMonth() + 1, day: date.getUTCDate() };
}

export function getMonthName(monthAsNumber: number) {
    switch (monthAsNumber) {
        case 1:
            return 'January';
        case 2:
            return 'February';
        case 3:
            return 'March';
        case 4:
            return 'April';
        case 5:
            return 'May';
        case 6:
            return 'June';
        case 7:
            return 'July';
        case 8:
            return 'August';
        case 9:
            return 'September';
        case 10:
            return 'October';
        case 11:
            return 'November';
        case 12:
            return 'December';
    }
}

export function getUTCDateString({ year, month, day, time }: DateObj): string {
    // time should be in format '9:00' or '12:15' NEVER with seconds.
    const addZero = (num: number) => `${num < 10 ? `0${num}` : num}`;
    const formatTime = (timeString: string) => `${timeString.length < 5 ? `0${timeString}` : timeString}`;
    return `${addZero(day)} ${getMonthName(month)} ${year} ${time ? formatTime(time) : '00:00'} UTC`;
}
export function getDateStringWithSeconds({ year, month, day, time }: DateObj): string {
    const addZero = (num: number) => `${num < 10 ? `0${num}` : num}`;
    const formatTime = (timeString: string) => `${timeString.length < 5 ? `0${timeString}` : timeString}:00`;
    return `${year}-${addZero(month)}-${addZero(day)}T${time ? formatTime(time) : '00:00:00'}`;
}

function getTimeStringAsInt(timeString?: string) {
    const timeAsInt = timeString && parseInt(timeString);
    return typeof timeAsInt === 'number' ? timeAsInt : undefined;
}

export function getBusinessHoursOpensAtInt(hoursOfBusiness?: IHoursOfBusiness[]) {
    const opensAt = new Date(hoursOfBusiness?.[0]?.opensAt || '');
    return getTimeStringAsInt(`${opensAt.getHours()}.${(opensAt.getMinutes() / 60) * 100}`);
}

export function getBusinessHoursClosesAtInt(hoursOfBusiness?: IHoursOfBusiness[]) {
    const closesAt = new Date(hoursOfBusiness?.[hoursOfBusiness.length - 1]?.closesAt || '');
    return getTimeStringAsInt(`${closesAt.getHours()}.${(closesAt.getMinutes() / 60) * 100}`);
}

export function getCleanUrl(name?: string | null): string {
    return name
        ? name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/[-_:.\s]/g, '')
        : '';
}

export function capitalizeString(val: string) {
    return val.charAt(0).toUpperCase() + val.slice(1);
}

// Creates array containing items in nested arrays
// Example:
// [ [1,2,3], [4,5,6], [7,8,9] ]
export function chunksArray(arrayData: any[], chunkSize: number): any[] {
    let index = 0;
    const chunks = [];

    for (index = 0; index < arrayData.length; index += chunkSize) {
        const myChunk = arrayData.slice(index, index + chunkSize);
        chunks.push(myChunk);
    }

    return chunks;
}

export function mergeObjects(obj1: any, obj2: any): any {
    return Object.assign(obj1, obj2);
}

export function objectIsEmpty(object: any): boolean {
    if (!object) {
        return true;
    }
    return Object.keys(object).length === 0;
}

export function getSizedEbayImage(url: string, width: number): string {
    return url.replace('s-l1600', `s-l${width}`);
}

export function getSizedBilinfoImage(url: string, width: number): string {
    const regex = new RegExp(/\[?class=\S+/i);
    return url.replace(regex, `class=S${width}X${width}`);
}

// Remove duplicates from array, by id
export function removeDuplicatesById(array: any[], id: string = 'id'): any[] {
    return array.reduce((arr, item) => {
        const exists = !!arr.find((x: any) => x[id] === item[id]);
        if (!exists) {
            arr.push(item);
        }
        return arr;
    }, []);
}

// Remove duplicate values from string array
export function removeStringDuplicates(array: any[]): any[] {
    return array.reduce((arr, item) => {
        const exists = !!arr.find((x: any) => x === item);
        if (!exists) {
            arr.push(item);
        }
        return arr;
    }, []);
}

// Umbraco returns 0/1 or true/false depending on how the data is parsed i.e. global spots return true/false.
// This method will validate what boolean value Umbraco parses, and ensure a boolean value is returned.
export function validateUmbracoBoolean(value: string | boolean): boolean {
    // You need to ensure value is checked as === true, to ensure it wont return true if the value is just a string
    return value === '1' || value === true;
}

// Helper function to test more amount of children than provided by data.
export function multiplyChildren(data: any, amount: number = 4) {
    if (data.children) {
        for (let it = 0; it < amount; it++) {
            for (let i = 0; i < data.children.length; i++) {
                data.children[i].children = data.children[i].children.reduce((res: any, current: any,) => {
                    return res.concat([current, current]);
                }, []);
            }
        }
    }

    return data;
}

export function mergeArrayObjectsByProperty(array1: any, array2: any, propertyName = 'id', secondaryPropertyName?: string) {
    if (secondaryPropertyName) {
        return array1.map((t1: any) => ({...t1, ...array2?.find((t2: any) => t2[secondaryPropertyName] === t1[propertyName])}))
    } else {
        return array1.map((t1: any) => ({...t1, ...array2?.find((t2: any) => t2[propertyName] === t1[propertyName])}))
    }
}

export function roundToClosestNumber(value: number, rounder: number = 10000, method: string = 'round'): number {
    if (method === 'round') {
        return Math.round(value / rounder) * rounder;
    } else if (method === 'floor') {
        return Math.floor(value / rounder) * rounder;
    } else if (method === 'ceil') {
        return Math.ceil(value / rounder) * rounder;
    } else {
        return Math.round(value / rounder) * rounder;
    }
}

export function getColorContrast(hexColor: string, contrastThreshold: number = 128): string {

    if (!hexColor) {
        return '';
    }

    // If a leading # is provided, remove it
    if (hexColor.slice(0, 1) === '#') {
        hexColor = hexColor.slice(1);
    }

    // If a three-character hexcode, make six-character
    if (hexColor.length === 3) {
        hexColor = hexColor.split('').map(function (hex) {
            return hex + hex;
        }).join('');
    }

    // Convert to RGB value
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);


    // Get YIQ ratio
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    // Check contrast
    return (yiq >= contrastThreshold) ? 'dark-contrast' : 'light-contrast';
}

export function removeAfterLastSlash(inputString: string): string {
    const lastSlashIndex = inputString.lastIndexOf('/');

    if (lastSlashIndex !== -1) {
        return inputString.substring(0, lastSlashIndex + 1);
    }
    return inputString;
}

// Fixes vehicle names in url. Example Citroën > citroen
// Add more characters if needed
export function sanitizeVehicleUrl(inputString: string): string {
    let resultString = decodeURIComponent(inputString).toLowerCase();

    const charMap = new Map([
        ['ë', 'e'],
        ['é', 'e'],
        ['ö', 'o'],
        ['ó', 'o'],
        ['ü', 'u'],
        ['û', 'u'],
      ]);
  
    charMap.forEach((replacement, original) => {
      const regex = new RegExp(original, 'g');
      resultString = resultString.replace(regex, replacement);
    });
  
    return resultString.replaceAll(' ', '-').replaceAll('.', '-').toLowerCase();
}
  
export function carouselSpotData(carouselItems: Cms.CarouselItem[]): Cms.CarouselSpot {
    return {
        items: carouselItems,
        id: '',
        name: '',
        type: ''
    };
}

export function getParentUrl(url: string): string {
    const urlSegments = url.split('/');
    // Remove the last segment
    urlSegments.pop();
    // Join the segments back into a URL
    return urlSegments.join('/');
}
