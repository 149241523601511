import { Component, Input } from '@angular/core';
import { Cms } from '@impact/data';

@Component({
    selector: 'app-mega-navigation-spot',
    template: `
        <div
            class="mega-navigation-spot-layout__spot"
            [ngClass]="{ 'has-background-image': data.image?.src }"
            [ngStyle]="data?.image?.src ? {
                'background-image': 'url(' + (data.image.src | resolveMediaUrl: { format: 'webp', width: 400, height: 400 }) + ')'
            } : {}"
            [ngSwitch]="data.link && data.link?.url !== undefined"
        >
            <ng-container *ngSwitchCase="true">
                <app-link *ngIf="data.link" [link]="data.link" [classString]="'mega-navigation-spot-layout__spot-content'" [showLinkText]="false">
                    <div class="text-content" *ngIf="data?.headline || data?.categoryHeadline || data?.text">
                        <h2 class="category-headline" *ngIf="data?.categoryHeadline">{{ data.categoryHeadline }}</h2>
                        <h1 class="headline" *ngIf="data?.headline">{{ data.headline }}</h1>
                        <p class="text" *ngIf="data?.text">{{ data.text }}</p>
                    </div>
                    <div class="button--primary">{{ data.link.name }}</div>
                </app-link>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <div class="mega-navigation-spot-layout__spot-content">
                    <div class="text-content" *ngIf="data?.headline || data?.categoryHeadline || data?.text">
                        <h2 class="category-headline" *ngIf="data?.categoryHeadline">{{ data.categoryHeadline }}</h2>
                        <h1 class="headline" *ngIf="data?.headline">{{ data.headline }}</h1>
                        <p class="text" *ngIf="data?.text">{{ data.text }}</p>
                    </div>
                </div>
            </ng-container>
        </div>
    `,
})
export class MegaNavigationSpotComponent {
    @Input() data: Cms.MegaNavigationSpotDto;
}
