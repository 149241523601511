import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cms, ILink } from '@impact/data';

import { progressIcon } from '../../icons';

@Component({
    selector: 'app-button',
    template: `
        <a
            *ngIf="link && link.url; else noLinkTmpl"
            (click)="handleClick.emit($event)"
            [href]="link.url"
            [ngClass]="buttonClass"
            [rel]="link.isExternal ? 'noopener' : ''"
            [target]="target || (link.isExternal ? '_blank' : '')"
            [title]="title || link.name"
            appInterceptLinks>
            {{ title || link?.name }}
            <ng-content></ng-content>
        </a>

        <ng-template #noLinkTmpl>
            <button
                (click)="handleClick.emit($event)"
                [class.button--loading]="loading"
                [disabled]="disabled || loading"
                [ngClass]="buttonClass"
                [title]="title || link?.name || ''"
                [type]="buttonType">
                <div class="button__content">
                    <span *ngIf="title || link?.name">{{ title || link?.name }}</span>
                    <ng-content></ng-content>
                </div>
                <div class="button__progress" *ngIf="loading">${progressIcon}</div>
            </button>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
    @Input() link?: ILink | Cms.Link;
    @Input() title?: string;
    @Input() buttonType = 'button';
    @Input() disabled = false;
    @Input() buttonClass: string;
    @Input() loading? = false;
    @Input() target = "";
    // handleClick() @Output is here if you need to disable a clickable button - since the regular (click) is NOT blocked by the disabled attr. Nice, Angular....
    @Output() handleClick = new EventEmitter();

    ngOnInit() {
        this.buttonClass = this.buttonClass ? this.buttonClass : this.setDefaultClasses();
    }

    private setDefaultClasses() {
        return 'button--primary';
    }
}
