import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    Input,
    isDevMode,
    OnInit,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { globalSpotAliasMap, spotAliasMap } from '../spot-alias-map';
import { ContentSpot } from '../spot-grid-types';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    selector: 'app-spot-renderer',
    template: '{{ spotJson }}',
})
export class SpotRendererComponent implements OnInit {
    spotJson = '';

    @Input() spot: ContentSpot;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit() {
        this.resolveSpot();

        if (isDevMode()) {
            this.spotJson = JSON.stringify(this.spot, undefined, 4);
        }
    }

    private async renderSpot(spotAlias: string, spotData: ContentSpot) {
        const componentType = (await spotAliasMap[spotAlias]?.())
            ?.LazySpotComponent;

        if (!componentType) {
            console.warn(
                '[SpotRendererComponent] Unable to resolve spot:',
                this.spot.type
            );

            return;
        }

        this.viewContainerRef.clear();

        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
                componentType
            );

        const componentRef =
            this.viewContainerRef.createComponent(componentFactory);

        componentRef.instance.data = spotData;

        this.changeDetectorRef.markForCheck();
    }

    private resolveSpot() {
        if (this.spot?.globalSpot) {
            const globalSpot = this.spot.globalSpot;
            const spotAlias = globalSpotAliasMap[globalSpot.type];
            this.renderSpot(spotAlias, globalSpot);
        } else {
            if (this.spot?.type) {
                this.renderSpot(this.spot.type, this.spot);
            }
        }
    }
}
