import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Cms, PAGE_TYPES } from '@impact/data';
import { SettingsService } from '../../core/settings.service';
import { NavigationService } from '../../navigation/navigation.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-ssr-main-navigation',
    template: `
        <nav>
            <ul class="ssr-main-navigation">
                <ng-container *ngFor="let level1Item of (mainNavigation$ | async)">
                    <li class="ssr-main-navigation__list-item" *ngIf="level1Item && level1Item.includeInNavigation && !level1Item.hideInMenu">

                        <!-- Top level in main navigation -->
                        <a class="ssr-main-navigation__list-item-link"
                            [routerLink]="level1Item?.pageLink?.cleanUrl || level1Item.url"
                            [queryParams]="level1Item?.pageLink?.urlParams">
                            {{ level1Item.name }}
                        </a>

                        <ng-container *ngIf="level1Item?.children">
                            <ng-container [ngSwitch]="level1Item.template">

                                <!-- NEW CARS -->
                                <ul *ngSwitchCase="pageTypes.NEW_VEHICLES_OVERVIEW_PAGE">
                                    <ng-container *ngFor="let level2Item of level1Item.children">
                                        <li *ngIf="level2Item && level2Item.includeInNavigation && !level2Item.hideInMenu">
                                            <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level2Item}"></ng-container>

                                            <ul *ngIf="level2Item?.children">
                                                <ng-container *ngFor="let level3Item of level2Item.children">
                                                    <li *ngIf="level3Item && level3Item.includeInNavigation && !level3Item.hideInMenu">
                                                        <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level3Item}"></ng-container>

                                                        <ul *ngIf="level3Item?.children">
                                                            <ng-container *ngFor="let level4Item of level3Item.children">
                                                                <li *ngIf="level4Item && level4Item.includeInNavigation && !level4Item.hideInMenu">
                                                                    <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level4Item}"></ng-container>
                                                                </li>
                                                            </ng-container>
                                                        </ul>
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </li>
                                    </ng-container>
                                </ul>

                                <!-- ARTICLES -->
                                <ng-container *ngSwitchCase="pageTypes.ARTICLES_OVERVIEW_PAGE">
                                    <ng-container *ngIf="level1Item?.articlesNavigationData">
                                        <ng-container *ngIf="level1Item?.articlesNavigationData?.vehicleMakesTagsNavigation">
                                            <div>{{level1Item?.articlesNavigationData?.vehicleMakesTagsHeadline}}</div>
                                            <ul>
                                                <li>
                                                    <a [routerLink]="articlesOverviewPageUrl">
                                                        {{'articles.show_all_articles_link_text' | translate}}
                                                    </a>
                                                </li>
                                                <li *ngFor="let item of level1Item?.articlesNavigationData?.vehicleMakesTagsNavigation">
                                                    <ng-container *ngTemplateOutlet="SSRNavigationTagLink; context: {item: item}"></ng-container>
                                                </li>
                                            </ul>
                                            <div>{{level1Item?.articlesNavigationData?.articleCategoriesTagNavigationHeader}}</div>
                                            <ul>
                                                <li *ngFor="let item of level1Item?.articlesNavigationData?.articleCategoriesTagNavigation">
                                                    <ng-container *ngTemplateOutlet="SSRNavigationTagLink; context: {item: item}"></ng-container>
                                                </li>
                                            </ul>

                                            <ng-container *ngIf="level1Item?.megaNavigationSpot?.link">
                                                <ng-container *ngTemplateOutlet="SSRNavigationSpot; context: {item: level1Item?.megaNavigationSpot}"></ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <!-- Column lists pages -->
                                <ng-container *ngSwitchDefault>
                                    <ul *ngIf="level1Item?.children">
                                        <ng-container *ngFor="let level2Item of level1Item.children">
                                            <li *ngIf="level2Item && level2Item.includeInNavigation && !level2Item.hideInMenu">
                                                <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level2Item}"></ng-container>

                                                <ul *ngIf="level2Item?.children">
                                                    <ng-container *ngFor="let level3Item of level2Item.children">
                                                        <li *ngIf="level3Item && level3Item.includeInNavigation && !level3Item.hideInMenu">
                                                            <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level3Item}"></ng-container>

                                                            <ul *ngIf="level3Item?.children">
                                                                <ng-container *ngFor="let level4Item of level3Item.children">
                                                                    <li *ngIf="level4Item && level4Item.includeInNavigation && !level4Item.hideInMenu">
                                                                        <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level4Item}"></ng-container>
                                                                    </li>
                                                                </ng-container>
                                                            </ul>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </li>
                                        </ng-container>
                                    </ul>

                                    <ng-container *ngIf="level1Item?.megaNavigationSpot?.link">
                                        <ng-container *ngTemplateOutlet="SSRNavigationSpot; context: {item: level1Item?.megaNavigationSpot}"></ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </li>
                </ng-container>
            </ul>
        </nav>

        <!-- Link Template -->
        <ng-template #SSRNavigationLink let-item="item">
            <a [routerLink]="item?.pageLink?.cleanUrl || item.url" [queryParams]="item?.pageLink?.urlParams">
                {{ item?.name }}
            </a>
        </ng-template>

        <!-- Tag link Template -->
        <ng-template #SSRNavigationTagLink let-item="item">
            <a [routerLink]="articlesOverviewPageUrl" [queryParams]="item?.param">{{item?.displayName}}</a>
        </ng-template>

        <!-- Mega navigation spot Template -->
        <ng-template #SSRNavigationSpot let-item="item">
            <a [routerLink]="item?.link?.cleanUrl || item?.link?.url" [queryParams]="item?.link?.urlParams">
                <span>{{item?.categoryHeadline}}</span>
                <span>{{item?.headline}}</span>
                <span>{{item?.text}}</span>
            </a>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SSRMainNavigtionComponent implements OnInit {
    pageTypes = PAGE_TYPES;
    mainNavigation$ = this.navigationService.mainNavigation$;
    articlesOverviewPageUrl: string;

    constructor(
        private navigationService: NavigationService,
        private settingsService: SettingsService
    ) {}

    ngOnInit() {
        this.settingsService.getGlobalPages().pipe(take(1)).subscribe((globalPages: any) => {
            this.articlesOverviewPageUrl = globalPages?.articlesOverviewPage?.url ?? undefined;
        });
    }
}
