import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Cms } from '@impact/data';
import { closeLightIcon } from '../../icons';
import { NotificationBarService } from '../core/notification-bar.service';

@Component({
    selector: 'app-notifications-bar',
    template: `
        <ul class="site-notifications">
            <li
                class="site-notification"
                *ngFor="let item of notifications$ | async"
                [style.backgroundColor]="item.bgColor"
                [style.color]="item.textColor"
            >
                <div class="site-notification__content">
                    <span [innerText]="item.text"></span>

                    <app-link
                        class="site-notification__link"
                        *ngIf="item.link"
                        [link]="item.link"
                    >
                        {{ 'generic.read_more_cta' | translate }}
                    </app-link>
                </div>

                <button
                    class="site-notification__close"
                    (click)="removeNotification(item)"
                >
                    ${closeLightIcon}
                </button>
            </li>
        </ul>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBarComponent {
    notifications$ = this.notificationBarService.notifications$;

    constructor(private notificationBarService: NotificationBarService) {}

    removeNotification(item: Cms.NotificationDto) {
        this.notificationBarService.closeNotification((item).id);
    }
}
