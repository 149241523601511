import { Environment } from '../../environment/environment';

const imageExtensions = /\.(jpg|jpeg|jfif|pjpeg|pjp|png|gif|avif|webp|apng)$/i;

/** @see https://docs.sixlabors.com/articles/imagesharp.web/processingcommands.html */
export interface ImageProcessorOptions {
    bgcolor?: string;
    format?: string;
    height?: number;
    quality?: number;
    rmode?: 'pad' | 'boxpad' | 'crop' | 'min' | 'max' | 'stretch';
    rxy?: string;
    width?: number;
}

export function isImage(url: string = '') {
    return imageExtensions.test(url);
}

function urlWithImageProcessorOptions(
    url: string,
    options: ImageProcessorOptions
) {
    let appendParam = url.includes('?');
    return Object.entries(options).reduce((a, [key, value]) => {
        if (!value) return a;

        const param = key + '=' + value.toString();
        const separator = appendParam ? '&' : '?';

        appendParam = true;
        return a + separator + param;
    }, encodeURI(url));
}

export function resolveMediaUrl(
    url: string,
    environment: Environment,
    options?: ImageProcessorOptions
) {
    const absoluteUrl = environment.mediaUrl + url;

    return options && isImage(absoluteUrl)
        ? urlWithImageProcessorOptions(absoluteUrl, options)
        : absoluteUrl;
}

const mediaUrlRegExp = /\s(href|src)=("|')\/media/gi;
const imagesRegExp = /<img\s/gi;
const lazyLoadingAttr = 'loading="lazy" ';

export function transformRichTextMedia(
    htmlString: string,
    { mediaUrl }: Environment,
    lazyLoad = false
) {
    if (typeof htmlString !== 'string') {
        return undefined;
    }

    const withMediaUrls = htmlString.replace(
        mediaUrlRegExp,
        (_, attr, quotes) => ` ${attr}=${quotes}${mediaUrl}/media`
    );

    return lazyLoad
        ? withMediaUrls.replace(imagesRegExp, (match) => match + lazyLoadingAttr)
        : withMediaUrls;
}
