import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Cms, HeaderThemes } from '@impact/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { arrowRightIcon, clockIcon, pinIcon, wrenchIcon } from '../../../icons';
import { SettingsService } from '../../core/settings.service';
import { MetaMenuTypes } from '../navigation-types';
import { NavigationService } from '../navigation.service';

@Component({
    selector: 'app-meta-menu',
    template: `
        <nav class="meta-menu" role="navigation" aria-label="meta menu">
            <ng-container [ngSwitch]="isB2B$ | async">
                <!-- B2C links -->
                <ng-container *ngSwitchCase="false">
                    <ul class="meta-menu__list">
                        <li
                            class="meta-menu__list-item"
                            [class.meta-menu__list-item--visible-in-mobile-header]="metaLink.visibleOnMobileHeader"
                            *ngFor="let metaLink of metaLinksB2C"
                            [ngSwitch]="metaLink?.isB2B2CLink"
                        >
                            <ng-container *ngSwitchCase="true">
                                <ng-container *ngTemplateOutlet="MetaB2B2CLink; context: { item: metaLink }"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="false">
                                <ng-container *ngTemplateOutlet="MetaLink; context: { item: metaLink }"></ng-container>
                            </ng-container>
                        </li>
                    </ul>
                </ng-container>
                <!-- B2B links -->
                <ng-container *ngSwitchCase="true">
                    <ul class="meta-menu__list">
                        <li
                            class="meta-menu__list-item"
                            [class.meta-menu__list-item--visible-in-mobile-header]="metaLink.visibleOnMobileHeader"
                            *ngFor="let metaLink of metaLinksB2B"
                            [ngSwitch]="metaLink?.isB2B2CLink"
                        >
                            <ng-container *ngSwitchCase="true">
                                <ng-container *ngTemplateOutlet="MetaB2B2CLink; context: { item: metaLink }"></ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="false">
                                <ng-container *ngTemplateOutlet="MetaLink; context: { item: metaLink }"></ng-container>
                            </ng-container>
                        </li>
                    </ul>
                </ng-container>
            </ng-container>
        </nav>


        <!--
            Templates
        -->

        <!-- Meta link template-->
        <ng-template #MetaLink let-item="item">
            <ng-container [ngSwitch]="item?.link?.isExternal">
                <ng-container *ngSwitchCase="true">
                    <ng-container *ngTemplateOutlet="ExternalMetaLink; context: { item: item }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                    <ng-container *ngTemplateOutlet="InternalMetaLink; context: { item: item }"></ng-container>
                </ng-container>
            </ng-container>
        </ng-template>

        <!-- Internal Meta link template -->
        <ng-template #InternalMetaLink let-item="item">
            <ng-container [ngSwitch]="isSidepanelLink(item?.link?.url)">
                <a *ngSwitchCase="true"
                    class="meta-menu__link"
                    [href]="item?.link?.url"
                    [attr.target]="item?.link?.target === '' ? null : item?.link?.target"
                    appInterceptLinks
                >
                    <ng-container *ngTemplateOutlet="MetaLinkIcon; context: { item: item }"></ng-container>
                    <span class="meta-menu__link-text">{{ item?.linkText }}</span>
                </a>
                <a *ngSwitchCase="false"
                    class="meta-menu__link"
                    [routerLink]="item?.link?.url"
                    [attr.target]="item?.link?.target === '' ? null : item?.link?.target"
                    appInterceptLinks
                >
                    <ng-container *ngTemplateOutlet="MetaLinkIcon; context: { item: item }"></ng-container>
                    <span class="meta-menu__link-text">{{ item?.linkText }}</span>
                </a>
            </ng-container>
        </ng-template>

        <!-- External Meta link template -->
        <ng-template #ExternalMetaLink let-item="item">
            <a class="meta-menu__link" [href]="item?.link?.url" target="_blank" rel="noopener">
                <ng-container *ngTemplateOutlet="MetaLinkIcon; context: { item: item }"></ng-container>
                <span class="meta-menu__link-text">{{ item?.linkText }}</span>
            </a>
        </ng-template>

        <!-- Meta Link icon template -->
        <ng-template #MetaLinkIcon let-item="item">
            <div class="meta-menu__link-icon" [ngSwitch]="!!item?.linkType && !item?.linkIconSolid">
                <ng-container *ngSwitchCase="true" [ngSwitch]="item?.linkType">
                    <i class="icon" *ngSwitchCase="metaMenuTypes.DEALERSHIPS">${pinIcon}</i>
                    <i class="icon" *ngSwitchCase="metaMenuTypes.AUTOSHOPS">${wrenchIcon}</i>
                    <i class="icon" *ngSwitchCase="metaMenuTypes.BOOK_MEETING">${clockIcon}</i>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                    <img [src]="item.linkIconTransparent?.src | resolveMediaUrl" class="icon link-icon__transparent" />
                    <img [src]="item.linkIconSolid?.src | resolveMediaUrl" class="icon link-icon__solid" />
                </ng-container>
            </div>
        </ng-template>

        <!-- Meta B2B2C link -->
        <ng-template #MetaB2B2CLink let-item="item">
            <ng-container [ngSwitch]="isB2B$ | async">
                <a *ngSwitchCase="true"
                    class="meta-menu__link meta-menu__link--b2b2c"
                    [href]="item?.b2cLink?.url"
                    [attr.target]="item?.b2cLink?.target === '' ? null : item?.b2cLink?.target"
                    rel="noopener"
                >
                    <ng-container *ngTemplateOutlet="B2B2CMetaLinkIcon; context: { item: item }"></ng-container>
                    <span class="meta-menu__link-text">{{ item?.b2cLinkText }}</span>
                </a>
                <a *ngSwitchCase="false"
                    class="meta-menu__link meta-menu__link--b2b2c"
                    [routerLink]="item?.b2bLink?.url"
                    routerLinkActive="is-active"
                    [attr.target]="item?.b2bLink?.target === '' ? null : item?.b2bLink?.target"
                    rel="noopener"
                >
                    <ng-container *ngTemplateOutlet="B2B2CMetaLinkIcon; context: { item: item }"></ng-container>
                    <span class="meta-menu__link-text">{{ item?.b2bLinkText }}</span>
                </a>
            </ng-container>
        </ng-template>

        <!-- B2B2C meta Link icon template -->
        <ng-template #B2B2CMetaLinkIcon let-item="item">
            <div class="meta-menu__link-icon">
                <i class="icon">${arrowRightIcon}</i>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaMenuComponent implements OnInit, OnDestroy {
    @Input() isInMobile = false;
    @Input() headerTheme: HeaderThemes;

    metaLinksB2C: (Cms.MetaLink | Cms.MetalinkB2B)[] = [];
    metaLinksB2B: (Cms.MetaLink | Cms.MetalinkB2B)[] = [];
    isTransparent: boolean;
    headerThemes: any = HeaderThemes;
    isB2B$ = this.navigationService.isB2B$;
    metaMenuTypes: any = MetaMenuTypes;

    private unsubscribe = new Subject<void>();

    constructor(private cd: ChangeDetectorRef, private settingsService: SettingsService, private navigationService: NavigationService) {}

    ngOnInit(): void {
        this.settingsService.getMetaLinks().pipe(takeUntil(this.unsubscribe)).subscribe((metaLinksData) => {
            const { metaLinksB2C, metaLinksB2B } = metaLinksData;
            this.metaLinksB2C = metaLinksB2C;
            this.metaLinksB2B = metaLinksB2B;
        });
    }

    isSidepanelLink(url: string): boolean {
        return url.indexOf('#sidepanel') !== -1;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
