import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Cms, PAGE_TYPES } from '@impact/data';

import { chevronRight } from '../../../icons';
import { NavigationService } from '../navigation.service';
import { megaNavigationColumnsMaxSize } from './mega-navigation.component';

@Component({
    selector: 'app-mega-navigation-list-columns',
    template: `
        <div class="mega-navigation-layout-wrapper">
            <div class="mega-navigation-layout-wrapper-inner">
                <div class="mega-navigation__content-header" [ngClass]="{'is-ready': isReady}">
                    <h2 class="mega-navigation-spot-layout__content-header-text">{{ data?.name }}</h2>
                </div>

                <div class="mega-navigation-content-layout" [ngClass]="{'is-ready': isReady}">
                    <div class="mega-navigation__content">
                        <!-- LEFT COLUMN -->
                        <div class="mega-navigation__content-column-left">
                            <ul class="mega-navigation__navigation-list">
                                <ng-container  *ngFor="let page of data?.children">
                                    <li *ngIf="page.includeInNavigation" class="mega-navigation__navigation-list-item">
                                        <ng-container [ngSwitch]="page.hasChildren">
                                            <ng-container *ngSwitchCase="true">
                                                <ng-container *ngTemplateOutlet="Level1Button; context: {
                                                    text: page?.name,
                                                    category: page?.name
                                                }"></ng-container>
                                            </ng-container>
                                            <a *ngSwitchCase="false"
                                               [routerLink]="page?.url"
                                               class="mega-navigation__navigation-list-link"
                                               (mouseover)="setActiveCategory('')">{{page.name}}</a>
                                        </ng-container>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="mega-navigation__content-column-right">
                            <!-- Sub pages -->
                            <ng-container *ngFor="let subPage of data?.children">
                                <ng-container *ngIf="subPage?.name === activeNavigationCategory">
                                    <div class="mega-navigation__scroll-container">
                                        <ng-container *ngTemplateOutlet="CategoryLinks; context: { items: subPage?.children }"></ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MEGA NAVIGATION SPOT -->
            <div class="mega-navigation__spot-wrapper" *ngIf="navigationSpot | hasData">
                <app-mega-navigation-spot [data]="navigationSpot"></app-mega-navigation-spot>
            </div>
        </div>

        <!-- TEMPLATES -->
        <ng-template #Level1Button let-text="text" let-category="category">
            <button type="button" class="mega-navigation__navigation-list-link"
                [ngClass]="{'is-active': activeNavigationCategory === category}"
                (click)="setActiveCategory(category)"
                (mouseover)="setActiveCategory(category)">
                {{text}}
            </button>
            <div class="mega-navigation__navigation-active-indicator">${chevronRight}</div>
        </ng-template>

        <ng-template #CategoryHeader let-text="text" let-url="url">
            <div class="mega-navigation-spot-layout__content-header">
                <h2 *ngIf="text"
                    class="mega-navigation-spot-layout__content-header-text">
                    {{ text }}
                </h2>
                <a [routerLink]="url" *ngIf="url" class="button--primary">Se alle {{ text }}</a>
            </div>
        </ng-template>

        <ng-template #CategoryLinks let-items="items">
            <ul class="mega-navigation-spot-layout__content-list triple-column"
                [ngClass]="{
                    'double-column': items.length > columnItemsSize,
                    'triple-column': items.length > columnItemsSize * 2
                }">
                <li *ngFor="let link of items">
                    <a [routerLink]="link?.url" [queryParams]="link?.param">{{ link?.name }}</a>
                </li>
            </ul>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegaNavigationListColumnsComponent implements OnChanges {
    @Input() data: Cms.NavigationDto;

    navigationSpot: Cms.MegaNavigationSpotDto;
    pageTypes = PAGE_TYPES;

    activeNavigationCategory: string;
    columnItemsSize = megaNavigationColumnsMaxSize;
    isReady: boolean;

    constructor(
        private cdr: ChangeDetectorRef,
        public navigationService: NavigationService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const pageData = changes.data?.currentValue;
        this.navigationSpot = changes.data.currentValue.megaNavigationSpot;
        this.data = pageData;
        this.isReady = false;
        this.cdr.markForCheck();

        setTimeout(() => {
            if (this.data.children) {
                this.setActiveCategory(this.data.children[0].name);
                this.isReady = true;
                this.cdr.markForCheck();
            }
        }, 0);
    }

    setActiveCategory(category: string) {
        this.activeNavigationCategory = category;
    }
}
