import { DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/da';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import {
    BrowserModule,
    BrowserTransferStateModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    apiHttpStateInterceptorProvider,
    apiInterceptorProvider,
    DEV_ENVIRONMENT_HOSTNAME,
    JsonLdModule,
    MetaModule,
    NotificationBarModule,
    PageModule,
    PageTransitionModule,
    SeoSiteFooterModule,
    SiteFooterModule,
    SiteHeaderModule,
    SiteLayoutModule,
    ssrHttpLogInterceptorProvider,
    TranslateLoaderService,
} from '@impact/ui';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'bag-ncg' }),
        BrowserTransferStateModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLoaderService,
            },
        }),
        MetaModule,
        PageModule.forRoot(),
        SiteLayoutModule,
        SiteFooterModule,
        SeoSiteFooterModule,
        SiteHeaderModule,
        PageTransitionModule,
        NotificationBarModule,
        AppRoutingModule,
        JsonLdModule,
    ],
    providers: [
        apiHttpStateInterceptorProvider,
        apiInterceptorProvider,
        {
            provide: LOCALE_ID,
            useValue: 'da-DK',
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'DKK',
        },
        {
            provide: DEV_ENVIRONMENT_HOSTNAME,
            useValue: 'dev-bilerneshus.dk',
        },
        DecimalPipe,
        ssrHttpLogInterceptorProvider,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
