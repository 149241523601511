import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { UtilsModule } from '../../utils/utils.module';
import { MegaNavigationSpotModule } from '../mega-navigation-spot/mega-navigation-spot.module';
import { MegaNavigationArticlesUniverseComponent } from './mega-navigation-articles-universe.component';
import { MegaNavigationLeasingCarsComponent } from './mega-navigation-leasing-cars.component';
import { MegaNavigationListColumnsComponent } from './mega-navigation-list-columns.component';
import { MegaNavigationNewCarsComponent } from './mega-navigation-new-cars/mega-navigation-new-cars.component';
import { MegaNavigationUsedCarsComponent } from './mega-navigation-used-cars.component';
import { MegaNavigationComponent } from './mega-navigation.component';
import { HasDataPipe } from './has-data.pipe';

@NgModule({
    imports: [CommonModule, UtilsModule, RouterModule, TranslateModule.forChild(), MegaNavigationSpotModule],
    exports: [
        MegaNavigationComponent,
        MegaNavigationArticlesUniverseComponent,
        MegaNavigationLeasingCarsComponent,
        MegaNavigationListColumnsComponent,
        MegaNavigationNewCarsComponent,
        MegaNavigationUsedCarsComponent,
    ],
    declarations: [
        MegaNavigationComponent,
        MegaNavigationArticlesUniverseComponent,
        MegaNavigationLeasingCarsComponent,
        MegaNavigationListColumnsComponent,
        MegaNavigationNewCarsComponent,
        MegaNavigationUsedCarsComponent,
        HasDataPipe,
    ],
    providers: [],
})
export class MegaNavigationModule {}
