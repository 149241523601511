import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

export enum COMPONENT_LOADER_SIZE {
    COMPONENT = 'component-size',
    PAGE = 'page-size'
}

@Component({
    selector: 'app-component-loader',
    template: `
    <ng-container [ngSwitch]="type">
        <div *ngSwitchCase="'horizontal'" class="loader-line"></div>
        <div *ngSwitchCase="'vertical'" class="loader-line-vertical-wrapper">
            <div class="loader-line-vertical"></div>
            <div class="loader-line-vertical"></div>
            <div class="loader-line-vertical"></div>
        </div>
    </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ComponentLoaderComponent implements OnInit {
    @Input() type?: string = 'horizontal';
    @Input() isPageSize?: boolean = false;
    
    @HostBinding('class') class: COMPONENT_LOADER_SIZE;

    ngOnInit() {
        this.class = this.getHostClass();
    }

    getHostClass() {
        return this.isPageSize ? COMPONENT_LOADER_SIZE.PAGE : COMPONENT_LOADER_SIZE.COMPONENT;
    }
}