import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Cms, PAGE_TYPES } from '@impact/data';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    switchMap,
    take,
} from 'rxjs/operators';
import { ContentService } from '../content/content.service';
import { SettingsService } from '../core/settings.service';
import { STORAGE_SERVICE_KEYS, StorageService } from '../core/storage.service';

const b2bKey = STORAGE_SERVICE_KEYS.USER_ACTIVE_BRAND_B2B;
const b2cKey = STORAGE_SERVICE_KEYS.USER_ACTIVE_BRAND_B2C;
const usedCarsOverviewPage = PAGE_TYPES.USED_CARS_OVERVIEW_PAGE;
const dealershipsOverviewPage = PAGE_TYPES.DEALERSHIPS_OVERVIEW_PAGE;

export interface Breadcrumb {
    name: string;
    url: string;
    id?: string;
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private activeBrandSubject$ = new ReplaySubject<string | undefined>(1);

    private rootNavigation$ = this.contentService.getRootNavigation().pipe(
        shareReplay({ bufferSize: 1, refCount: false })
    );

    private b2bRoot$ = combineLatest([
        this.rootNavigation$,
        this.settingsService.get(),
    ]).pipe(
        map(([rootNavigation, settings]) => {
            const pageId = settings.globalPages?.b2bSectionPageId;
            const b2bRoot = pageId
                ? rootNavigation.children.find((n) => n.id === pageId)
                : undefined;

            return b2bRoot;
        })
    );

    activeBrand$ = this.activeBrandSubject$.pipe(distinctUntilChanged());

    currentUrl$ = this.router.events.pipe(
        filter((e) => e instanceof NavigationStart),
        map((e) => (e as any).url as string),
        shareReplay({ bufferSize: 1, refCount: false })
    );

    isB2B$ = combineLatest([this.currentUrl$, this.b2bRoot$]).pipe(
        map(
            ([currentUrl, b2bRoot]) =>
                !!b2bRoot && currentUrl.startsWith(b2bRoot.url)
        ),
        distinctUntilChanged()
    );

    mainNavigation$ = this.isB2B$.pipe(
        switchMap((isB2B) => (isB2B ? this.b2bRoot$ : this.rootNavigation$)),
        map((navigation) => navigation?.children ?? [])
    );

    usedCarsNavigation$ = this.mainNavigation$.pipe(
        map((items) => {
            const usedCarsPage = items.find(
                (i) => i.template === usedCarsOverviewPage
            );

            return usedCarsPage?.children ?? [];
        })
    );

    dealershipsNavigation$ = this.mainNavigation$.pipe(
        map((items) => {
            const dealershipsPage = items.find(
                (i) => i.template === dealershipsOverviewPage
            );

            return dealershipsPage?.children ?? [];
        })
    );

    constructor(
        private settingsService: SettingsService,
        private storageService: StorageService,
        private router: Router,
        private contentService: ContentService
    ) {
        this.isB2B$.subscribe((isB2B) => {
            const key = isB2B ? b2bKey : b2cKey;
            const activeBrand = this.storageService.getItem(key);
            this.activeBrandSubject$.next(activeBrand);
        });
    }

    /** Gets the second level of navigation nodes for the current URL. */
    getCurrentSubTree() {
        const currentUrl = this.router.url;

        return this.rootNavigation$.pipe(
            take(1),
            map((rootNavigation) => {
                const firstLevel = this.findAncestorOrSelfInNodes(
                    currentUrl,
                    rootNavigation.children
                );

                const secondLevel = this.findAncestorOrSelfInNodes(
                    currentUrl,
                    firstLevel?.children ?? []
                );

                return secondLevel;
            })
        );
    }

    /** Gets the navigation tree for a given URL. */
    getTreeByUrl(url: string) {
        return this.rootNavigation$.pipe(
            take(1),
            map((rootNavigation) => {
                let ancestorOrSelf = this.findAncestorOrSelfInNodes(
                    url,
                    rootNavigation.children
                );

                while (ancestorOrSelf) {
                    if (ancestorOrSelf.url === url) return ancestorOrSelf;
                    ancestorOrSelf = this.findAncestorOrSelfInNodes(
                        url,
                        ancestorOrSelf.children
                    );
            }
            })
        );
    }

    getMainNavigation() {
        return this.mainNavigation$.pipe(take(1));
    }

    /**
     * Returns an observable that emits an array of breadcrumb objects based on the provided current URL.
     * @param currentUrl The current URL to generate breadcrumbs from.
     * @returns An observable that emits an array of breadcrumb objects.
     */
    getBreadcrumb(currentUrl: string): Observable<Breadcrumb[]> {
        // Split the current URL into segments, filtering out empty strings
        // const segments = currentUrl.split('/').filter(segment => segment);
        const cleanUrl = currentUrl.split('?')[0].split('#')[0]; // Remove parameters and hash
        const segments = cleanUrl.split('/').filter(segment => segment);

        // Use rootNavigation$
        return this.rootNavigation$.pipe(
            map((navigationItems) => this.buildBreadcrumb(segments, navigationItems))
        );
    }

    /**
     * Builds a breadcrumb array from the given URL segments and navigation items.
     *
     * @param segments The URL segments to build the breadcrumb from.
     * @param navigationItems The navigation items to search within.
     * @returns An array of breadcrumb objects.
     */
    buildBreadcrumb(segments: string[], navigationItems: Cms.NavigationDto): Breadcrumb[] {
        const breadcrumb: Breadcrumb[] = [];

        /**
         * Recursively searches for a matching item in the navigation tree based on the provided segment.
         *
         * @param segment The segment to match with the item's URL.
         * @param items The navigation items to search within.
         * @returns The matched navigation item or null if no match is found.
         */
        function findMatchingItem(segment: string, items: Cms.NavigationDto[]): Cms.NavigationDto | null {
            for (const item of items) {
                // Match the segment with the item's URL (skipping the first "/" for comparison)
                if (item.url.endsWith(segment)) {
                    return item;
                }
                if (item.children && item.children.length > 0) {
                    const foundChild = findMatchingItem(segment, item.children);
                    if (foundChild) {
                        return foundChild;
                    }
                }
            }

            return null;
        }

        // Iterate over URL segments, find each corresponding item in the navigation tree
        let currentItems = navigationItems.children; // Start from the first level children, skipping the root because we dont't want to have the front page as a part of the breadcrumb

        for (const segment of segments) {
            const matchingItem = findMatchingItem(segment, currentItems);
            if (matchingItem) {
                breadcrumb.push({
                    name: matchingItem.name,
                    url: matchingItem.url,
                    id: matchingItem.id
                });
                currentItems = matchingItem.children; // Move to the next level children
            } else {
                break; // Stop building the breadcrumb if a segment doesn't match
            }
        }

        return breadcrumb;
    }

    setActiveBrand(brandName: string | undefined) {
        if (!brandName) return;

        this.isB2B$.pipe(take(1)).subscribe((isB2B) => {
            const key = isB2B ? b2bKey : b2cKey;

            this.storageService.setItem(key, brandName);
            this.activeBrandSubject$.next(brandName);
        });
    }

    private findAncestorOrSelfInNodes(
        descendantUrl: string,
        nodes: Cms.NavigationDto[]
    ) {
        return nodes.find(
            ({ url }) =>
                descendantUrl === url || descendantUrl.startsWith(url + '/')
        );
    }
}
