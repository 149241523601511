import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';

class DocumentTouch {}

export enum KEY_CODES {
    ESCAPE = 'Escape',
}

export enum BODY_CLASS {
    APP_READY = 'app-ready',
    SITEHEADER_TRANSPARENT = 'site-header-transparent',
    DEV_MODE = 'dev-mode',
    MEGA_NAVIGATION_ACTIVE = 'mega-navigation-active',
    NOT_IOS = 'not-ios',
}

@Injectable({
    providedIn: 'root',
})
export class FeatureDetectionService {
    private _isTouchDevice?: boolean;
    private prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-', ''];
    private _hasWebAnimations = false;
    bodyHtmlTag: HTMLElement;
    private resizeSubject$: Subject<any>;

    get onResize$(): Observable<any> {
        return this.resizeSubject$.asObservable();
    }

    constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(DOCUMENT) private doc: Document, private eventManager: EventManager) {
        this.resizeSubject$ = new Subject();
        this.eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
    }

    private onResize(event: any) {
        let viewport: any;
        if (event?.target && this.isBrowser()) {
            viewport = {
                height: event.target.innerHeight,
                width: event.target.innerWidth,
            };
        }
        this.resizeSubject$.next(viewport);
    }

    isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    isServer(): boolean {
        return isPlatformServer(this.platformId);
    }

    hasGeolocation(): boolean {
        return !!(this.isBrowser() && window.navigator.geolocation);
    }

    isTouchDevice(): boolean {
        if (this._isTouchDevice !== undefined) {
            return this._isTouchDevice;
        }

        // Link: https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/4819886#4819886
        if (this.isServer()) {
            this._isTouchDevice = false;
        } else {
            const mq = (queries: string) => {
                this._isTouchDevice = window.matchMedia(queries).matches;
                return this._isTouchDevice;
            };

            if ('ontouchstart' in window || ('DocumentTouch' in window && document instanceof DocumentTouch)) {
                return (this._isTouchDevice = true);
            }

            // include the 'heartz' as a way to have a non matching MQ to help terminate the join
            // https://git.io/vznFH
            const query = ['(', this.prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
            this._isTouchDevice = mq(query);
        }

        return this._isTouchDevice;
    }

    browserSpecificHacks() {
        if (!this.isBrowser()) {
            return;
        }

        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (!iOS) {
            this.addBodyClass(BODY_CLASS.NOT_IOS);
        }
    }

    hasIntersectionObserver(): boolean {
        return this.isBrowser() && 'IntersectionObserver' in window;
    }

    appReady(): void {
        if (this.isBrowser()) {
            this.bodyHtmlTag = this.doc.getElementsByTagName('body')[0];
        }

        const appReadyTimeout = setTimeout(() => {
            this.addBodyClass(BODY_CLASS.APP_READY);
            clearTimeout(appReadyTimeout);
        }, 500);
    }

    transparentHeaderMode(isTransparent: boolean): void {
        if (isTransparent) {
            this.addBodyClass(BODY_CLASS.SITEHEADER_TRANSPARENT);
        } else {
            this.removeBodyClass(BODY_CLASS.SITEHEADER_TRANSPARENT);
        }
    }

    // Add class to body, to show development breakpoints
    devTools(isProduction: boolean): void {
        if (!isProduction) {
            this.addBodyClass(BODY_CLASS.DEV_MODE);
        }
    }

    addBodyClass(className: string): void {
        if (!className) {
            return;
        }

        if (this.isBrowser()) {
            if (!this.bodyHtmlTag) {
                this.bodyHtmlTag = this.doc.getElementsByTagName('body')[0];
            }

            this.bodyHtmlTag.classList.add(className);
        }
    }

    removeBodyClass(className: string): void {
        if (!className) {
            return;
        }

        if (this.isBrowser()) {
            if (!this.bodyHtmlTag) {
                this.bodyHtmlTag = this.doc.getElementsByTagName('body')[0];
            }

            this.bodyHtmlTag.classList.remove(className);
        }
    }

    hasWebAnimation(): boolean {
        if (this._hasWebAnimations) {
            return true;
        }

        if (this.isBrowser()) {
            const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
            // Don't run animation, if user prefer reduced motion
            if (!mediaQuery || mediaQuery.matches) {
                return this._hasWebAnimations;
            }

            if ('animate' in window.document.body) {
                const el = document.createElement('div');
                const animation = el.animate([], undefined);
                if (animation && 'finished' in animation) {
                    this._hasWebAnimations = true;
                }
            }
        }

        return this._hasWebAnimations;
    }
}
