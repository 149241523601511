import { Type } from '@angular/core';
import { CarouselSpotComponent } from './carousel-spot/carousel-spot.component';

export const globalSpotAliasMap: Record<string, string> = {
    'globalMediaTextSplitSpot': 'imageTextSplitSpot',
    'globalRichTextSpot': 'richTextEditorSpot',
    'globalUspSpot': 'uspSpot',
    'globalGridSpot': 'gridSpot',
    'globalCarouselContentSpot':'carouselContentSpot',
    'globalWorkshopServiceSpot': 'workshopServiceSpot',
}

// prettier-ignore
export const spotAliasMap: Record<string, () => Promise<{ LazySpotComponent: Type<any> }>> = {
    'accordionListSpot': () =>
        import('./accordion-spot/accordion-spot.component'),
    'articlesSpot': () =>
        import('./articles-spot/articles-spot.component'),
    'authorSpot': () =>
        import('./author-info-spot/author-info-spot.component'),
    'bookUsedCarTestDriveCtaSpot': () =>
        import('./book-used-car-test-drive-cta-spot/book-used-car-test-drive-cta-spot.component'),
    'campaignsOverviewSpot': () =>
        import('../new-cars/campaigns-overview-spot/campaigns-overview-spot.component'),
    'carouselContentSpot': () =>
        import('./carousel-content-spot/carousel-content-spot.component'),
    'carouselSpot': () =>
        Promise.resolve(({ LazySpotComponent: CarouselSpotComponent })),
    'dealershipContentSpot': () =>
        import('./dealership-content-spot/dealership-content-spot.component'),
    'disclaimerSpot': () =>
        import('./disclaimer-spot/disclaimer-spot.component'),
    'euroNCAPSpot': () =>
        import('./euro-ncap-score-spot/euro-ncap-score-spot.component'),
    'exteriorInteriorGallerySpot': () =>
        import('./exterior-interior-gallery-spot/exterior-interior-gallery-spot.component'),
    'factBoxSpot': () =>
        import('./fact-box-spot/fact-box-spot.component'),
    'gridSpot': () =>
        import('./grid-spot/grid-spot.component'),
    'headlineAndTextSpot': () =>
        import('./headline-and-text-spot/headline-and-text-spot.component'),
    'imageLinksGridSpot': () =>
        import('./image-links-grid-spot/image-links-grid-spot.component'),
    'imageTextSplitSpot': () =>
        import('./image-text-split-spot/image-text-split-spot.component'),
    'imageVideoSpot': () =>
        import('./image-video-text-spot/image-video-text-spot.component'),
    'louconLeasingVariantsSpot': () =>
        import('./loucon-leasing-variants-spot/loucon-leasing-variants-spot.component'),
    'newCarsModelCardListSpot': () =>
        import('./new-cars-model-card-list-spot/new-cars-model-card-list-spot.component'),
    'newsletterSignUpSpot': () =>
        import('./newsletter-signup-spot/newsletter-signup-spot.component'),
    'overlayBoxSpot': () =>
        import('./overlay-box-spot/overlay-box-spot.component'),
    'quoteFromExpertSpot': () =>
        import('./quote-from-expert-spot/quote-from-expert-spot.component'),
    'quoteSpot': () =>
        import('./quote-spot/quote-spot.component'),
    'reviewsSpot': () =>
        import('./reviews-spot/reviews-spot.component'),
    'richTextEditorSpot': () =>
        import('./rte-spot/rte-spot.component'),
    'trustboxSpot': () =>
        import('./trustbox-spot/trustbox-spot.component'),
    'definitionsTableSpot': () =>
        import('./definitions-table-spot/definitions-table-spot.component'),
    'usedCarsFiltersSpot': () =>
        import('./used-cars-filters-spot/used-cars-filters-spot.component'),
    'usedCarsListQuerySpot': () =>
        import('./used-cars-list-query-spot/used-cars-list-query-spot.component'),
    'uspSpot': () =>
        import('./usp-spot/usp-spot.component'),
    'vehicleVariantsLeasingSpot': () =>
        import('../new-cars/vehicle-variants-leasing-spot/vehicle-variants-leasing-spot.component'),
    'vehicleVariantsOverviewSpot': () =>
        import('../new-cars/vehicle-variants-overview-spot/vehicle-variants-overview-spot.component'),
    'workshopServiceSpot': () =>
        import('./workshop-service-spot/workshop-service-spot.component'),
}
