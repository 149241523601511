import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Cms } from '@impact/data';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { TrackingService } from '../utils/tracking/tracking.service';
import { ContentService } from '../content/content.service';

export enum SiteIds {
    BNDK = 'Bjarne Nielsen',
    BHDK = 'Bilernes Hus',
    LEASINGDK = 'Leasing',
    MOTORPOINT = 'Motorpoint',
}

export interface SettingsMetaLinks {
    metaLinksB2C: (Cms.MetaLink | Cms.MetalinkB2B)[];
    metaLinksB2B: (Cms.MetaLink | Cms.MetalinkB2B)[];
}


@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    // Use a single ReplaySubject for site settings
    private siteSettings$ = new ReplaySubject<Cms.SettingsDto>(1);

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private trackingService: TrackingService,
        private contentService: ContentService
    ) {
        // Subscribe to settings from content service
        this.contentService.getSettings().subscribe(settings => {
            // Emit the entire settings object
            this.siteSettings$.next(settings);

            // Additional initialization logic
            this.setBrowserLanguage('da');
            this.trackingService.initGtm(settings.gtmId ?? '');
        });
    }

    // Expose the entire settings object
    get(): Observable<Cms.SettingsDto> {
        return this.siteSettings$.pipe(take(1));
    }

    // Derive specific settings using RxJS operators, instead of separate subjects
    getSiteId(): Observable<string> {
        return this.siteSettings$.pipe(map(settings => settings.siteNameId));
    }

    getGlobalPages(): Observable<Cms.GlobalPages> {
        return this.siteSettings$.pipe(map(settings => settings.globalPages));
    }

    getGlobalUSPs(): Observable<Cms.UspItem[]> {
        return this.siteSettings$.pipe(map(settings => settings.globalUspList));
    }

    getGlobalUSPb2bs(): Observable<Cms.UspItem[]> {
        return this.siteSettings$.pipe(map(settings => settings.globalUspListB2B));
    }

    getMetaLinks(): Observable<SettingsMetaLinks> {
        return this.siteSettings$.pipe(map(settings => ({
            metaLinksB2C: settings.metaLinks,
            metaLinksB2B: settings.metaLinksB2B
        })));
    }

    // Set the browser language based on the settings
    private setBrowserLanguage(languageKey: string): void {
        if (this._document && this._document.documentElement) {
            this._document.documentElement.lang = languageKey;
        }
    }
}