import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { TempAny } from '@impact/data';
import { StyleBreakpoints } from '../models/style-breakpoints';
import { FeatureDetectionService } from './helpers/feature-detection.service';

const imageBreakpoints = [
    {
        breakpoint: StyleBreakpoints.Implicit,
        width: 360,
    },
    {
        breakpoint: StyleBreakpoints.Xsmall,
        width: 460,
    },
    {
        breakpoint: StyleBreakpoints.Small,
        width: 768,
    },
    {
        breakpoint: StyleBreakpoints.Medium,
        width: 1024,
    },
    {
        breakpoint: StyleBreakpoints.Large,
        width: 1200,
    },
    {
        breakpoint: StyleBreakpoints.Xlarge,
        width: 2000,
    },
];

@Component({
    selector: 'app-video-player',
    template: `
        <video
            appImageLoadFadeIn
            autoplay
            class="video-player"
            height="9"
            loop
            muted
            playsinline
            width="16"
            #videoElement
            *ngIf="!!video; else vimeoVideo"
            [poster]="fallbackImage?.src | resolveMediaUrl : { format: 'webp' }"
            [preload]="preload"
        >
            <source [src]="video" (error)="videoFail()" />
        </video>

        <ng-template #vimeoVideo>
            <app-vimeo-player
                [videoId]="vimeoId"
                [thumbnail]="fallbackImage"
            ></app-vimeo-player>
        </ng-template>

        <!-- If video fails to load -->
        <app-responsive-image
            *ngIf="videoFailed && fallbackImage"
            [image]="fallbackImage"
            [breakpoints]="imageBreakpoints"
            class="video-player__image"
        >
        </app-responsive-image>
    `,
})
export class VideoPlayerComponent implements AfterViewInit {
    @Input() fallbackImage?: TempAny;
    @Input() preload = 'auto';
    @Input() video?: string;
    @Input() vimeoId?: string;
    @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;

    imageBreakpoints = imageBreakpoints;
    videoFailed: boolean;

    constructor(private featureDetectionService: FeatureDetectionService) {}

    ngAfterViewInit() {
        // Ensure video is muted - fixes autoplay issues
        if (this.featureDetectionService.isBrowser() && this.videoElement) {
            this.videoElement.nativeElement.muted = true;
            this.videoElement.nativeElement.play();
        }
    }

    videoFail() {
        this.videoFailed = true;
    }
}
