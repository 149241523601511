import {
    HTTP_INTERCEPTORS,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';

@Injectable({
    providedIn: 'root',
})
export class SsrHttpLogInterceptor implements HttpInterceptor {
    private readonly isServer: boolean;

    constructor(featureDetectionService: FeatureDetectionService) {
        this.isServer = featureDetectionService.isServer();
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler) {
        if (this.isServer) {
            console.debug("[SsrHttpLogInterceptor] Fetching " + request.urlWithParams);
        }

        return next.handle(request);
    }
}

export const ssrHttpLogInterceptorProvider: Provider = {
    deps: [FeatureDetectionService],
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: SsrHttpLogInterceptor,
};
