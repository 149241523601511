import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Cms } from '@impact/data';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Environment, ENVIRONMENT } from '../environment/environment';
import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';

const stateKeyRoot = 'content';

type Params = Record<string, string | string[] | number>;

type Translations = {
    [key: string]: Record<string, string>;
};

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    private readonly articlesUrl: string;
    private readonly contentUrl: string;
    private readonly globalDataUrl: string;
    private readonly isServer: boolean;
    private readonly navigationUrl: string;
    private readonly settingsUrl: string;
    private readonly translationsUrl: string;
    private readonly vehicleDataUrl: string;
    private readonly sidepanelsUrl: string;
    private readonly leasingDataUrl: string;
    private readonly siteNotificationsUrl: string;

    constructor(
        private httpClient: HttpClient,
        private transferState: TransferState,
        @Inject(ENVIRONMENT) environment: Environment,
        featureDetectionService: FeatureDetectionService
    ) {
        this.isServer = featureDetectionService.isServer();

        this.articlesUrl = `${environment.cmsUrl}/dk/da/${environment.environmentKey}/articles`;
        this.contentUrl = `${environment.cmsUrl}/dk/da/${environment.environmentKey}/content`;
        this.globalDataUrl = `${environment.cmsUrl}/dk/da/globalData`;
        this.navigationUrl = `${environment.cmsUrl}/dk/da/${environment.environmentKey}/navigation`;
        this.settingsUrl = `${environment.cmsUrl}/dk/da/${environment.environmentKey}/settings`;
        this.translationsUrl = `${environment.cmsUrl}/dk/da/globalization/translations`;
        this.vehicleDataUrl = `${environment.cmsUrl}/dk/da/${environment.environmentKey}/vehicledata`;
        this.sidepanelsUrl = `${environment.cmsUrl}/dk/da/${environment.environmentKey}/sidepanels/getsidepanel`;
        this.leasingDataUrl = `${environment.cmsUrl}/dk/da/leasingData`;
        this.siteNotificationsUrl = `${environment.cmsUrl}/dk/da/${environment.environmentKey}/notifications`;
    }

    /**
     * Retrieves articles from the CMS based on the given parameters.
     *
     * @param skip - The number of articles to skip.
     * @param take - The maximum number of articles to return.
     * @param categories - The categories guids to filter articles by.
     * @param makes - The vehicle makes guids to filter articles by.
     *
     * @return An observable of the retrieved articles.
     */
    getArticles(
        skip: number,
        take: number,
        categories?: string[],
        makes?: string[]
    ) {
        // Create a params object, only adding non-empty parameters
        const params: Params = { skip, take };

        if (categories && categories.length) {
            params['categoryTags'] = categories;
        }

        if (makes && makes.length) {
            params['vehicleTags'] = makes;
        }

        return this.getAndStore<Cms.ArticlesViewModel>(
            ['articles', skip, take],
            this.articlesUrl,
            params
        );
    }

    getGlobalData() {
        return this.getAndStore<Cms.GlobalDataDto>(
            ['global-data'],
            this.globalDataUrl
        );
    }

    getPage<T extends Cms.PageBase = Cms.PageBase>(path: string) {
        const url = this.contentUrl + '/pages';

        const params = {
            path,
        };

        return this.getAndStore<T | Cms.Redirect>(['page', path], url, params);
    }

    getRootNavigation() {
        return this.getAndStore<Cms.NavigationDto>(
            ['navigationData'],
            this.navigationUrl,
            { levels: 5 }
        );
    }

    getSettings() {
        return this.getAndStore<Cms.SettingsDto>(
            ['settings'],
            this.settingsUrl
        );
    }

    getTranslations() {
        return this.getAndStore<Translations>(
            ['translations'],
            this.translationsUrl
        );
    }

    getVehicleMakes() {
        const url = this.vehicleDataUrl + '/getvehiclemakes';

        return this.getAndStore<Cms.VehicleMakeDto[]>(['vehicle-makes'], url);
    }

    getVehicleModelAndVariants(modelId: string) {
        const url =
            this.vehicleDataUrl + '/getvehiclemodelandvariants/' + modelId;

        return this.getAndStore<Cms.VehicleModelVariantsDto>(
            ['vehicle-model-and-variants', modelId],
            url
        );
    }

    getVehicleModels(makeId: string) {
        const url = this.vehicleDataUrl + '/getvehiclemodels/' + makeId;

        return this.getAndStore<
            {
                id: string;
                name: string;
            }[]
        >(['vehicle-models', makeId], url);
    }

    getVehicleModelCardList(makePageId: string) {
        const url = this.vehicleDataUrl + '/getvehiclecards/' + makePageId;

        return this.getAndStore<Cms.VehicleCardsDto>(
            ['vehicle-model-card-list', makePageId],
            url
        );
    }

    getSidepanel(sidepanelUrl: string) {
        const lastSegment = sidepanelUrl.split('/').pop();
        const url = `${this.sidepanelsUrl}/${lastSegment}`;

        return this.getAndStore<Cms.Sidepanel>(['sidepanel'], url);
    }

    getLeasingData() {
        return this.getAndStore<Cms.LeasingDataDto>(['leasingData'], this.leasingDataUrl);
    }

    getSiteNotifications() {
        return this.getAndStore<Cms.NotificationsDto>(['siteNotifications'], this.siteNotificationsUrl).pipe(
            map((response) => response.notifications || []),
            catchError((error) => {
                console.error('Error fetching notifications:', error);
                return [];
            })
        );
    }
    

    private get<T>(url: string, params: Params = {}): Observable<T> {
        return this.httpClient.get<T>(url, {
            params,
        });
    }

    /**
     * Retrieves data from the given URL and stores it in the transfer state
     * under the given key, so that it can be reused on the server-side.
     *
     * If the data is already present in the transfer state, it is returned
     * immediately.
     *
     * @param key - The key to use for storing the data in the transfer state.
     * @param url - The URL to retrieve the data from.
     * @param params - The parameters to pass to the GET request.
     *
     * @return An observable of the retrieved data.
     */
    private getAndStore<T>(
        key: (string | number)[],
        url: string,
        params: Params = {},
    ): Observable<T> {
        const stateKey = makeStateKey<T>([stateKeyRoot, ...key].join(':'));
        const state = this.transferState.get(stateKey, undefined);
    
        if (state) {
            return of(state);
        }
    
        return this.get<T>(url, params).pipe(
            tap((r) => {
                if (this.isServer) this.transferState.set(stateKey, r);
            }),
            catchError((error) => {
                console.error('[BN ContentService: Failed to fetch data]', error?.error?.status);
                // Return fallback data or an empty object, if provided
                return of(error);
            })
        );
    }
}
