import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@impactdk/ngx-overlay';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';
import { ExpansionPanelModule } from '../expansion-panel/expansion-panel.module';
import { LeasingModule } from '../leasing/leasing.module';
import { MobileOverlayModule } from '../mobile-overlay/mobile-overlay.module';
import { UtilsModule } from '../utils/utils.module';
import { LeasingVehicleAnchorNavigationComponent } from './leasing-vehicle-anchor-navigation/leasing-vehicle-anchor-navigation.component';
import { MainNavigationModule } from './main-navigation/main-navigation.module';
import { MobileArticlesNavComponent } from './mobile-menu/mobile-articles-nav.component';
import { MobileDefaultNavComponent } from './mobile-menu/mobile-default-nav.component';
import { MobileMenuLinkItemComponent } from './mobile-menu/mobile-menu-link-item.component';
import { MobileMenuTriggerDirective } from './mobile-menu/mobile-menu-trigger.directive';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { MobileMetaListComponent } from './mobile-menu/mobile-meta-list.component';
import { MobileNavListComponent } from './mobile-menu/mobile-nav-list.component';
import { MobileNewCarsBrandModelsNavComponent } from './mobile-menu/mobile-new-cars-brand-models-nav.component';
import { MobileBrandOverviewNavComponent } from './mobile-menu/mobile-brand-overview-nav.component';
import { MobileUsedCarsBrandsModelsNavComponent } from './mobile-menu/mobile-used-cars-brands-models-nav.component';
import { MobileUsedCarsNavComponent } from './mobile-menu/mobile-used-cars-nav.component';
import { MegaNavigationSpotModule } from './mega-navigation-spot/mega-navigation-spot.module';
import { PageSecondaryNavigationComponent } from './page-secondary-navigation/page-secondary-navigation.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { TagFilterComponent } from './tag-navigation/tag-navigation.component';
import { IsExpandedPipe } from './side-navigation/is-expanded.pipe';
import { IsActivePipe } from './side-navigation/is-active.pipe';

@NgModule({
    declarations: [
        LeasingVehicleAnchorNavigationComponent,
        MobileArticlesNavComponent,
        MobileBrandOverviewNavComponent,
        MobileDefaultNavComponent,
        MobileMenuComponent,
        MobileMenuLinkItemComponent,
        MobileMenuTriggerDirective,
        MobileMetaListComponent,
        MobileNavListComponent,
        MobileNewCarsBrandModelsNavComponent,
        MobileUsedCarsBrandsModelsNavComponent,
        MobileUsedCarsNavComponent,
        PageSecondaryNavigationComponent,
        SideNavigationComponent,
        SideNavigationComponent,
        TagFilterComponent,
        IsExpandedPipe,
        IsActivePipe,
    ],
    imports: [
        CommonModule,
        OverlayModule,
        RouterModule,
        TranslateModule.forChild(),
        BrowserAnimationsModule,
        UtilsModule,
        ExpansionPanelModule,
        ButtonModule,
        MobileOverlayModule,
        LeasingModule,
        MainNavigationModule,
        MegaNavigationSpotModule,
    ],
    exports: [
        MobileMenuTriggerDirective,
        PageSecondaryNavigationComponent,
        LeasingVehicleAnchorNavigationComponent,
        SideNavigationComponent,
        TagFilterComponent,
    ],
})
export class NavigationModule {}
