import { IImage } from '@impact/data';
import { isImage } from './media-helpers';

export type CroppingMode = 'pad' | 'boxpad' | 'crop' | 'min' | 'max' | 'stretch';

/**
 * Interface for Umbraco resize options
 *
 * @link http://imageprocessor.org/imageprocessor-web/imageprocessingmodule/resize/
 */
export interface IImageOptions {
    /**
     * Width of new image in pixels
     */
    width?: number;

    /**
     * Suggested width ratio for resizing
     */
    widthratio?: number;

    /**
     * Height of new image in pixels
     */
    height?: number;

    /**
     * Suggested height ratio for resizing
     */
    heightratio?: number;

    /**
     * Hexadecimal string value of background color to use for box padding
     */
    bgcolor?: string;

    /**
     * Amount of blur
     *
     * Example: 10
     */
    blur?: number;

    /**
     * Sigma
     * Default: 1.4
     */
    sigma?: number;

    /**
     * Threshold
     * Default: 0
     */
    threshold?: number;

    /**
     * Crops an image to the area of greatest entropy.
     * This method works best with images containing large areas of a single color or similar colors around the edges.
     *
     * Example: 128
     */
    entropycrop?: number;

    /**
     * Adds rounded corners to the current image.
     *
     * Example: 100
     */
    roundedcorners?: number;

    /**
     * Anchored point in image unless `center` is specified
     * 'Center' or 'Top' is default in many cases
     */
    anchor?: 'center' | 'top' | 'right' | 'bottom' | 'left' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright';

    /**
     * Fraction to crop as close to as possible
     *
     * Value: y,x
     * Example: 0.25,0.3
     */
    center?: string;

    /**
     * Mode of cropping, 'pad' is default in Umbraco
     */
    mode?: CroppingMode;

    /**
     * Quality of new image in percent
     *
     * Example: "25" - 25 % quality
     * Default: "100"
     */
    quality?: string;

    /**
     * Image format
     *
     * Example: "webp"
     * Default: "undefined"
     * Only works if Umbraco has the ImageProcessor webp plugin installed
     * Guide: https://piotrbach.com/enable-webp-image-format-in-umbraco-cms
     */
    format?: string;
}

/** @deprecated Use `resolveMediaUrl` instead. */
export function ImageUrl(src: IImage, options?: IImageOptions): string {
    const settings: IImageOptions = options || {};

    if (!src.url) {
        return '';
    }

    if (src.focalPoint && settings.mode && settings.mode === 'crop' && !settings.anchor && !settings.center) {
        settings.center = `${src.focalPoint.top},${src.focalPoint.left}`;
    }

    let queryParams = Object.entries(settings)
        .map((entry) => entry[1] && `${entry[0]}=${entry[1].toString()}&`)
        .join('');
    queryParams = queryParams.slice(0, -1);

    let concatenatedUrl: string;

    if (src.url.indexOf('?') !== -1) {
        concatenatedUrl = `${encodeURI(src.url)}${queryParams.length ? '&' + queryParams : ''}`;
    } else {
        concatenatedUrl = `${encodeURI(src.url)}${queryParams.length ? '?' + queryParams : ''}`;
    }

    return concatenatedUrl;
}

/** @deprecated Use `isImage` instead. */
export function mediaIsImage(media: string | IImage | undefined = '') {
    return isImage(typeof media === 'string' ? media : media.url);
}
