import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Cms, TempAny } from '@impact/data';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { chevronRight } from '../../../icons';
import {
    mobileAnimation,
    MobileAnimationState,
} from '../../utils/animations/mobile-menu.animations';
import { MobileMenuTypes } from './mobile-menu';
import { MobileNavigationService } from './mobile-navigation.service';

@Component({
    selector: 'app-mobile-brand-overview-nav',
    template: `
        <div [@slideContent]="animationState">
            <div class="mobile-menu__section-header">
                <h1 *ngIf="navigation.template !== 'usedCarsOverviewPage'">{{ navigation.name }}</h1>
                <h1 *ngIf="navigation.template === 'usedCarsOverviewPage'">{{ 'navigation.select_brand' | translate }}</h1>
            </div>

            <div class="mobile-menu__scroll-container mobile-menu__scroll-container--sub-header">
                <ul class="mobile-menu__list">
                    <li class="mobile-menu-item">
                        <a [routerLink]="navigation.url" (click)="overlayRef.close()">
                            <span class="mobile-menu-item__text">{{ 'car_data.see_all_from_brand' | translate }} {{ navigation.name }}</span>
                            <i class="mobile-menu-item__icon--nav">${chevronRight}</i>
                        </a>
                    </li>
                    <li *ngFor="let item of navigation.children" class="mobile-menu-item">
                        <app-mobile-menu-link-item [item]="item" (btnClick)="navigate(item)"></app-mobile-menu-link-item>
                    </li>
                </ul>

                <ng-container *ngIf="navigation?.megaNavigationNewCarsOptionalMenu?.links?.length">
                    <ul class="mobile-menu__list mobile-menu__list--secondary">
                        <li
                            *ngFor="let item of navigation.megaNavigationNewCarsOptionalMenu.links"
                            class="mobile-menu-item mobile-menu-item--secondary"
                        >
                            <a class="button--minimal" [routerLink]="item.url" (click)="overlayRef.close()">
                                <span class="mobile-menu-item__text">{{ item.name }}</span>
                            </a>
                        </li>
                    </ul>
                </ng-container>

                <!-- NAVIGATION SPOT -->
                <div class="mobile-menu__spot-wrapper" *ngIf="navigation.megaNavigationSpot">
                    <app-mega-navigation-spot [data]="navigation.megaNavigationSpot"></app-mega-navigation-spot>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [mobileAnimation],
})
export class MobileBrandOverviewNavComponent {
    @Input() navigation: Cms.NavigationDto;

    animationState: MobileAnimationState = 'enter';

    constructor(public overlayRef: ImpactOverlayRef, private mobileNavigationService: MobileNavigationService) {}

    navigate(item: Cms.NavigationDto) {
        const isUsedCarsContext = this.navigation.template === 'usedCarsOverviewPage' || this.navigation.template === 'vehicleLeasingOverviewPage';
        this.mobileNavigationService.navigateByObject({
            type: isUsedCarsContext ? MobileMenuTypes.UsedCarsBrandModels : MobileMenuTypes.NewCarsBrandModels,
            navigation: item,
            brandNodeId: item.id,
        });
    }
}
