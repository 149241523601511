import { PlatformLocation } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';
import { Environment, resolveEnvironment } from '@impact/environments';
export { Environment } from '@impact/environments';

export const DEV_ENVIRONMENT_HOSTNAME = new InjectionToken<string>('');

export const ENVIRONMENT = new InjectionToken<Environment>('', {
    factory: () =>
        resolveEnvironment(
            inject(PlatformLocation).hostname,
            inject(DEV_ENVIRONMENT_HOSTNAME)
        ),
    providedIn: 'root',
});
