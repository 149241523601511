import * as ApiGateway from './generated/api-gateway';

export type ServiceDto = ApiGateway.BNDracarApiServiceDto;
export type ShopDto = ApiGateway.BNDracarApiShopDto;
export type BrandDto = ApiGateway.BNDracarApiMakeDto;
export type ModelDto = ApiGateway.BNDracarApiModelDto;
export type WorkshopCar = ApiGateway.BNApiGatewayApiWorkshopCar;
export type OptionDto = ApiGateway.BNDracarApiOptionDto;
export type CarPickDto = ApiGateway.BNDracarApiCarPickupDto;
export type CreateBookingRequestDto = ApiGateway.BNDracarApiRequestsCreateBookingRequestDto;
export import BOOKWORKSHOP_SITE_IDS = ApiGateway.BNDracarApiBrandName;
export type CheckoutGetPurchaseOrderResponse = ApiGateway.BNApiGatewayApiCheckoutGetPurchaseOrderResponse;
export type PurchaseOrderProductDto = ApiGateway.BNPurchaseOrderApiProductDto;
export type IAtermarketEquipment = ApiGateway.BNPurchaseOrderApiEquipmentDto;
export type ISetAtermarketEquipmentRequest = ApiGateway.BNPurchaseOrderApiRequestsEquipmentRequestDto;
export type IAtermarketEquipmentTotalOrderPriceResponse = ApiGateway.BNApiGatewayApiCheckoutCalculateTotalOrderPriceResponse;
export type CheckoutGetPermissionResponse = ApiGateway.BNConsentApiGetPermissionResponseDto;
export type CheckoutOwner = ApiGateway.BNPurchaseOrderApiOwnerDto;
export type CheckoutDeliveryDto = ApiGateway.BNPurchaseOrderApiDeliveryDto;
export type CheckoutCreatePurchaseOrderRequest = ApiGateway.BNApiGatewayApiCheckoutCreatePurchaseOrderRequest;
export type RequestsUpdateCashRequestDto = ApiGateway.BNPurchaseOrderApiRequestsUpdateCashRequestDto;
export type RequestsUpdateInsuranceRequestDto = ApiGateway.BNPurchaseOrderApiRequestsUpdateInsuranceRequestDto;
export type CheckoutUpdateOwnershipRequest = ApiGateway.BNPurchaseOrderApiRequestsUpdateOwnershipRequestDto;
export type ResponsesUpdateCompletedDto = ApiGateway.BNPurchaseOrderApiResponsesUpdateCompletedDto;
export type RequestsUpdatePickupDeliveryRequestDto = ApiGateway.BNPurchaseOrderApiRequestsUpdatePickupDeliveryRequestDto;
export type GoToPaymentRequest = ApiGateway.BNPurchaseOrderApiRequestsCompletePurchaseOrderRequestDto;
export type GoToPaymentResponse = ApiGateway.BNPaymentApiPaymentCreatedDto;
export type ValidatePRResponse = ApiGateway.BNPurchaseOrderApiResponsesValidationResponseDto;
export type RequestCreatePriceLead = ApiGateway.BNSidePanelsApiRequestsCreatePriceLeadRequestDto;
export type PostExchangeCarRequest = ApiGateway.BNPurchaseOrderApiRequestsExchangeCarRequestDto;
export type LoanRequest = ApiGateway.BNApiGatewayApiLoanLoanRequest;
export type LoanResponse = ApiGateway.BNApiGatewayApiLoanLoanResponse;
export type LoanResponseMultipleInterests = ApiGateway.BNApiGatewayApiLoanLoanMultipleInterestsResponse;
export type ISetLoanRequestBody = ApiGateway.BNPurchaseOrderApiRequestsUpdateLoanRequestDto;
export type ISetLoanType = ApiGateway.BNPurchaseOrderApiLoanDto;
export type Leasing_GetValidIntervalsRequest = ApiGateway.BNLouconApiGetValidIntervalsRequestDto;
export type Leasing_GetValidIntervalsResponse = ApiGateway.BNLouconApiGetValidIntervalsResponseDto;
export type IFilterResponse = ApiGateway.BNCarSearchApiFilterCarsResponseDto;
export type IFilterFacet = ApiGateway.BNCarSearchApiViewModelFacetViewModel;
export type IFilterFacetItem = ApiGateway.BNCarSearchApiViewModelFacetItemViewModel;
export type IFilterHitItem = ApiGateway.BNCarSearchApiViewModelCarShortViewModel;
export type IGetCarDetailsResponse = ApiGateway.BNCarSearchApiGetCarDetailByIdResponseDto;
export type ICarDetails = ApiGateway.BNCarSearchApiViewModelCarViewModel;
export type ExchangeCarData = ApiGateway.BNPurchaseOrderApiExchangeCar;
export type IFieldGrouping = ApiGateway.BNCarSearchApiViewModelFieldGroupingViewModel;
export type IBookTestDriveNewCarRequest = ApiGateway.BNSidePanelsApiRequestsBookTestDriveNewCarRequestDto;
export type IBookTestDriveUsedCarRequest = ApiGateway.BNSidePanelsApiRequestsBookTestDriveUsedCarRequestDto;
export type IGetCarOfferNewCarRequest = ApiGateway.BNSidePanelsApiRequestsGetCarOfferNewCarRequestDto;
export type IBookMeetingRequest = ApiGateway.BNSidePanelsApiRequestsBookMeetingRequestDto;
export type ICreateAskExpertRequest = ApiGateway.BNSidePanelsApiRequestsCreateAskExpertRequestDto;

// LEASING
export type IOperationalLeasingRequest = ApiGateway.BNLouconApiOperationalLeasingDto;
export type ILeasingGetCarListRequest = ApiGateway.BNApiGatewayApiLeasingGetCarListRequest;
export type ILeasingOptionDescriptionsRequest = ApiGateway.BNLouconApiGetCarOptionDescriptionsRequestDto;
export type ILeasingColorDescriptionsRequest = ApiGateway.BNLouconApiGetCarColorDescriptionsRequestDto;
export type ILeasingCarOptionsRequest = ApiGateway.BNLouconApiGetCarOptionsRequestDto;
export type ILeasingExtraOptionsRequest = ApiGateway.BNLouconApiGetCarExtraOptionsRequestDto;
export type ILeasingCarPriceRequest = ApiGateway.BNLouconApiGetCarPriceRequestDto;
export type ILeasingValidIntervalsRequest = ApiGateway.BNLouconApiGetValidIntervalsRequestDto;
export type ILeasingCreateLeasingOrderRequest = ApiGateway.BNLeasingPurchaseOrderApiRequestsCreateLeasingPurchaseOrderRequestDto;
export type ILeasingUpdateOptionsRequest = ApiGateway.BNLeasingPurchaseOrderApiRequestsUpdateLeasingOptionsRequestDto;
export type ILeasingUpdateExtraOptionsRequest = ApiGateway.BNLeasingPurchaseOrderApiRequestsUpdateLeasingExtraOptionsRequestDto;
export type ILeasingPlanUpdateRequest = ApiGateway.BNLeasingPurchaseOrderApiRequestsUpdateLeasingDataRequestDto;
export type ILeasingPickupDeliveryUpdateRequest = ApiGateway.BNLeasingPurchaseOrderApiRequestsUpdateLeasingPickupDeliveryRequestDto;
export type ILeasingUserInfoUpdateRequest = ApiGateway.BNLeasingPurchaseOrderApiRequestsUpdateLeasingUserInfoRequestDto;
export type ILeasingStepInfoUpdateRequest = ApiGateway.BNLeasingPurchaseOrderApiRequestsUpdateLeasingStepInfoDto;
export type ILeasingUsedCarIntegratedValuesRequest = ApiGateway.BNLouconApiGetUsedCarIntegratedValuesRequestDto;
export type ILeasingUsedCarIntegratedValuesResponse = ApiGateway.BNLouconApiGetUsedCarIntegratedValuesResponseDto;


// USED CAR LEASING
export type ILeasingUsedCarValidIntervalsRequest = ApiGateway.BNLouconApiGetUsedCarValidIntervalsRequestDto;
export type ILeasingUsedCarValidIntervalsResponse = ApiGateway.BNLouconApiGetUsedCarValidIntervalsResponseDto;
export type ILeasingUsedCarPriceRequest = ApiGateway.BNLouconApiGetUsedCarPriceRequestDto;
export type ILeasingUsedCarPriceResponse = ApiGateway.BNLouconApiGetUsedCarPriceResponseDto;
export type ILeasingUsedCarUpdateRequest = ApiGateway.BNPurchaseOrderApiRequestsUpdateLeasingRequestDto;

export import DELIVERYTYPES = ApiGateway.BNPurchaseOrderApiDeliveryTypes;
export import BRANDNAMES = ApiGateway.BNSidePanelsApiBrandName;
export import MEETINGTYPES = ApiGateway.BNSidePanelsApiMeetingType;
export type RentalCarClass = ApiGateway.BNDracarApiCarClassDto;

export type LeasingCarListInfo = ApiGateway.BNLouconApiCarDto;
export type ILeasingGetCarListResponse = ApiGateway.BNLouconApiGetCarListResponseDto;
export type ILeasingOptionDescriptionsResponse = ApiGateway.BNLouconApiGetCarOptionDescriptionsResponseDto;
export type ILeasingColorDescriptionsResponse = ApiGateway.BNLouconApiGetCarColorDescriptionsResponseDto;
export type ILeasingCarOptionsResponse = ApiGateway.BNLouconApiGetCarOptionsResponseDto; 
export type ILeasingCarExtraOptionsResponse = ApiGateway.BNLouconApiGetCarExtraOptionsResponseDto;
export type ILeasingValidIntervalsResponse = ApiGateway.BNLouconApiGetValidIntervalsResponseDto;
export type ILeasingOrderCompletedResponse = ApiGateway.BNLeasingPurchaseOrderApiResponsesCreateLeasingPurchaseOrderUpdateResponseDto;

export type ILeasingDataColors = ApiGateway.BNLeasingPurchaseOrderApiLeasingColorsDto;
export type ILeasingOptions = ApiGateway.BNLeasingPurchaseOrderApiLeasingOptionDto;
export type ILeasingExtraOptions = ApiGateway.BNLeasingPurchaseOrderApiLeasingExtraOptionDto;
export type ILeasingPrimaryUser = ApiGateway.BNLeasingPurchaseOrderApiLeasingPrimaryUserDto;
export type ILeasingUser = ApiGateway.BNLeasingPurchaseOrderApiLeasingUserDto;
export type ILeasingDelivery = ApiGateway.BNLeasingPurchaseOrderApiLeasingDeliveryDto;
export type ILeasingCoreData = ApiGateway.BNLeasingPurchaseOrderApiLeasingDataDto;

export interface DateObj {
    year: number;
    month: number;
    day: number;
    time?: string;
}

export const API_GATEWAY_PATH = 'api-gateway';
