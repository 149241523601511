import { CommonModule, Location } from '@angular/common';
import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgModule,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Cms, Tag, TempAny } from '@impact/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { FormElementsModule } from '../../../lib/form-elements/form-elements.module';
import { ComponentLoaderModule } from '../../component-loader/component-loader.module';
import { chunksArray, getStringParameters } from '../../core/helpers';
import { LoadMoreModule } from '../../load-more/load-more.module';
import { StyleBreakpoints } from '../../models/style-breakpoints';
import { NavigationModule } from '../../navigation/navigation.module';
import { RichTextModule } from '../../rich-text/rich-text.module';
import { SectionHeaderModule } from '../../section-header/section-header.module';
import { SpotsUiModule } from '../../spots/spots-ui.module';
import { IImageBreakpoints } from '../../utils/responsive-image.component';
import { UtilsModule } from '../../utils/utils.module';
import { ContentService } from '../../content/content.service';

const ARTICLE_ROWS_INCREMENT = 10;

export enum ArticleFilterTypes {
    CATEGORY = 'category',
    MAKE = 'brand',
}

type ArticleGroup = Cms.ArticleDto[][];

@Component({
    selector: 'app-articles-overview-page',
    template: `
        <main>
            <section class="page-content layout-wrapper">
                <h1>{{ data?.name }}</h1>

                <!-- Articles filters -->
                <div class="articles-overview-filter">
                    <div class="articles-overview-filter--mobile">
                        <!-- Mobile filtering -->
                        <label class="selector articles-overview-filter__select">
                            <select
                                class="selector__select selector__select--not-empty tag-navigation__select"
                                #categoryFilterSelect
                                (change)="filterSelectChange(categoryFilterSelect.value, articleFilterTypesEnum.CATEGORY)"
                            >
                                <option [selected]="activeCategoryFilter.id === defaultCategoryFilter.id" [value]="defaultCategoryFilter.key">
                                    {{ 'car_data.vehicle_type_filter_button_all' | translate }}
                                </option>
                                <option
                                    *ngFor="let item of articleCategoriesTagList"
                                    [selected]="activeCategoryFilter.id === item.id"
                                    [value]="item.id"
                                >
                                    {{ item?.displayName }}
                                </option>
                            </select>
                            <span class="selector__label">
                                {{ data?.articleCategoriesTagNavigationHeader }}
                            </span>
                        </label>

                        <!-- Mobile filtering -->
                        <label class="selector articles-overview-filter__select">
                            <select
                                class="selector__select selector__select--not-empty tag-navigation__select"
                                #brandFilterSelect
                                (change)="filterSelectChange(brandFilterSelect.value, articleFilterTypesEnum.MAKE)"
                            >
                                <option [selected]="activeMakesFilter.id === defaultMakesFilter.id" [value]="defaultMakesFilter.key">
                                    {{ 'car_data.vehicle_type_filter_button_all' | translate }}
                                </option>
                                <option
                                    *ngFor="let item of articleMakesTagList"
                                    [selected]="activeMakesFilter.id === item.id"
                                    [value]="item.id"
                                >
                                    {{ item?.displayName }}
                                </option>
                            </select>
                            <span class="selector__label">
                                {{ data?.vehicleMakesTagsHeadline }}
                            </span>
                        </label>
                    </div>

                    <!-- Desktop filtering -->
                    <div class="articles-overview-filter--desktop">
                        <!-- Category tag filter -->
                        <h6 class="component-header--small articles-overview-filter__header">{{ data?.articleCategoriesTagNavigationHeader }}</h6>
                        <ul class="tag-navigation__list">
                            <li class="tag-navigation__list-item">
                                <button
                                    type="button"
                                    class="tag-navigation__button"
                                    [ngClass]="{ 'active-item': activeCategoryFilter.id === defaultCategoryFilter.id }"
                                    (click)="filterArticles(defaultCategoryFilter)"
                                >
                                    {{ 'car_data.vehicle_type_filter_button_all' | translate }}
                                </button>
                            </li>
                            <li *ngFor="let item of articleCategoriesTagList" class="tag-navigation__list-item">
                                <button
                                    type="button"
                                    class="tag-navigation__button"
                                    [ngClass]="{ 'active-item': activeCategoryFilter?.id === item?.id }"
                                    (click)="filterArticles(item)"
                                >
                                    {{ item?.displayName }}
                                </button>
                            </li>
                        </ul>

                        <!-- Make tag filter -->
                        <h6 class="component-header--small articles-overview-filter__header">{{ data?.vehicleMakesTagsHeadline }}</h6>
                        <ul class="tag-navigation__list">
                            <li class="tag-navigation__list-item">
                                <button
                                    class="tag-navigation__button"
                                    [ngClass]="{ 'active-item': activeMakesFilter.id === defaultMakesFilter.id }"
                                    (click)="filterArticles(defaultMakesFilter)"
                                >
                                    {{ 'car_data.vehicle_type_filter_button_all' | translate }}
                                </button>
                            </li>
                            <li *ngFor="let item of articleMakesTagList" class="tag-navigation__list-item">
                                <button
                                    class="tag-navigation__button"
                                    [ngClass]="{ 'active-item': activeMakesFilter === item }"
                                    (click)="filterArticles(item)"
                                >
                                    {{ item?.displayName }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Article spots list -->
                <ng-container>
                    <div class="articles-overview__list"
                        #articlesListElement [ngStyle]="{'min-height': articlesListHeight + 'px'}">
                        <ng-container *ngFor="let section of allArticlesInSections; let i = index; trackBy: trackById">
                            <ng-container [ngSwitch]="i % 4">
                                <!-- MOSAIC LTR -->
                                <div class="articles-overview__list-grid--mosaic mosaic-grid--ltr" *ngSwitchCase="0">
                                    <ng-container *ngFor="let spot of section; let i = index" [ngSwitch]="i">
                                        <div class="mosaic-spot--large" *ngSwitchCase="0">
                                            <ng-container *ngTemplateOutlet="SpotItem; context: { item: spot, size: 'large' }"></ng-container>
                                        </div>
                                        <div
                                            class="mosaic-spot--small"
                                            [ngClass]="{
                                                'mosaic-spot--small-first': i === 1,
                                                'mosaic-spot--small-second': i === 2
                                            }"
                                            *ngSwitchDefault
                                        >
                                            <ng-container *ngTemplateOutlet="SpotItem; context: { item: spot, size: 'small' }"></ng-container>
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- GRID ROW -->
                                <div class="articles-overview__list-grid--row" *ngSwitchCase="1">
                                    <ng-container *ngFor="let spot of section">
                                        <ng-container *ngTemplateOutlet="SpotItem; context: { item: spot, size: 'small' }"></ng-container>
                                    </ng-container>
                                </div>

                                <!-- MOSAIC RTL -->
                                <div class="articles-overview__list-grid--mosaic mosaic-grid--rtl" *ngSwitchCase="2">
                                    <ng-container *ngFor="let spot of section; let i = index" [ngSwitch]="i">
                                        <div class="mosaic-spot--large" *ngSwitchCase="1">
                                            <ng-container *ngTemplateOutlet="SpotItem; context: { item: spot, size: 'large' }"></ng-container>
                                        </div>
                                        <div
                                            class="mosaic-spot--small"
                                            [ngClass]="{
                                                'mosaic-spot--small-first': i === 0,
                                                'mosaic-spot--small-second': i === 2
                                            }"
                                            *ngSwitchDefault
                                        >
                                            <ng-container *ngTemplateOutlet="SpotItem; context: { item: spot, size: 'small' }"></ng-container>
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- GRID ROW -->
                                <div class="articles-overview__list-grid--row" *ngSwitchCase="3">
                                    <ng-container *ngFor="let spot of section">
                                        <ng-container *ngTemplateOutlet="SpotItem; context: { item: spot, size: 'small' }"></ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>

                        <div class="no-articles-to-show" *ngIf="!showingArticlesAmount && !fetchingArticles && afterFirstRender">
                            <div>
                                <h5>{{ 'articles.no_articles_found_header' | translate }}</h5>
                                <p *ngIf="activeCategoryFilter.id !== defaultCategoryFilter.id || activeMakesFilter.id !== defaultMakesFilter.id">
                                    {{ 'articles.no_articles_found_text' | translate }}
                                </p>
                            </div>
                        </div>

                        <app-component-loader *ngIf="fetchingArticles"></app-component-loader>
                        <app-load-more
                            *ngIf="take * page < allArticlesAmount"
                            [disabled]="fetchingArticles"
                            (handleClick)="showMoreArticles()"
                            [shownItems]="showingArticlesAmount"
                            [totalItems]="allArticlesAmount"
                            [incrementSize]="take"
                            [loadMoreUrl]="nextUrl"
                            [statusText]="
                                'articles.articles_amount_showing_text' | translate: { amount: showingArticlesAmount, totalAmount: allArticlesAmount }
                            "
                            [buttonText]="'articles.show_more_button_text' | translate"
                        >
                        </app-load-more>
                    </div>
                </ng-container>
            </section>
        </main>

        <!-- Page content grid -->
        <section class="page-grid-content" *ngIf="data?.pageBottomGrid">
            <app-spot-grid [grid]="data?.pageBottomGrid"></app-spot-grid>
        </section>

        <!-- TEMPLATES -->
        <ng-template #SpotItem let-spot="item" let-size="size">
            <a *ngIf="spot?.url" [routerLink]="spot.url" class="article-spot-item">
                <div class="article-spot-item__image-wrapper">
                    <app-responsive-image
                        *ngIf="spot?.introImage"
                        [image]="spot?.introImage"
                        [breakpoints]="size === 'small' ? smallImageBreakpoints : largeImageBreakpoints"
                        class="article-spot-item__image"
                    >
                    </app-responsive-image>
                </div>
                <div class="article-spot-item__content">
                    <div class="article-spot-item__info">
                        <div class="article-spot-item__tags" *ngIf="spot?.categoryTags || spot?.vehicleBrandTags">
                            <div *ngFor="let tag of spot?.categoryTags; let i = index" class="article__info-tag">
                                <div *ngIf="i === 0">
                                    {{ tag?.displayName }}
                                </div>
                            </div>
                            <div *ngFor="let tag of spot?.vehicleBrandTags; let i = index" class="article__info-tag">
                                <div *ngIf="i === 0">
                                    {{ tag?.displayName }}
                                </div>
                            </div>
                        </div>
                        <div class="article-spot-item__date">
                            <div class="article-spot-item__info-seperator">|</div>
                            <time [attr.datetime]="spot?.createdDate">{{ spot?.createdDate | date: 'dd. MMM yyyy' }}</time>
                        </div>
                    </div>
                    <h2 [appColonSplit]="spot?.title" class="article-spot-item__content-header">{{ spot?.title }}</h2>
                    <p class="article-spot-item__content-text" *ngIf="size === 'large'">
                        {{ spot?.subTitle }}
                    </p>
                </div>
            </a>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlesOverviewPageComponent implements OnInit, AfterViewChecked, OnDestroy {
    @ViewChild('articlesListElement') articlesListElement: ElementRef;
    @Input() data?: Cms.ArticlesOverviewPage;
    @Input() appColonSplit?: string;

    private readonly unsubscribe = new Subject();

    allArticleSpots: Cms.ArticleDto[] = []; // All articles, unformatted
    allArticlesInSections: ArticleGroup = []; // All articles, divided into sections (chunks)
    articleSpotsToShow: Cms.ArticleDto[] = []; // Currently active spots
    allArticlesAmount: number; // How many articles match active filtering
    showingArticlesAmount: number; // How many articles are currently displayed

    articleCategoriesTagList: Cms.ArticleTagNavigationDto[] = [];
    articleMakesTagList: Cms.ArticleTagNavigationDto[] = [];

    activeCategoryFilter: Cms.ArticleTagNavigationDto;
    defaultCategoryFilter: Cms.ArticleTagNavigationDto;
    activeMakesFilter: Cms.ArticleTagNavigationDto;
    defaultMakesFilter: Cms.ArticleTagNavigationDto;

    fetchingArticles = false;
    afterFirstRender = false;

    smallImageBreakpoints: IImageBreakpoints[];
    largeImageBreakpoints: IImageBreakpoints[];

    take: number = ARTICLE_ROWS_INCREMENT * 3;
    page: number;
    skip: number;

    articleFilterTypesEnum = ArticleFilterTypes;

    articlesListHeight: number;
    currentArticlesListHeight: number;

    nextUrl?: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private cdr: ChangeDetectorRef,
        private contentService: ContentService,
        private translateService: TranslateService
    ) {
        // Create default filters (show all articles)
        this.defaultCategoryFilter = {
            displayName: this.translateService.instant('car_data.vehicle_type_filter_button_all'),
            key: 'all',
            param: {
                [ArticleFilterTypes.CATEGORY]: 'all',
            },
            id: '',
        };

        this.defaultMakesFilter = {
            displayName: this.translateService.instant('car_data.vehicle_type_filter_button_all'),
            key: 'all',
            param: {
                [ArticleFilterTypes.MAKE]: 'all',
            },
            id: '',
        };

        this.activeCategoryFilter = this.defaultCategoryFilter;
        this.activeMakesFilter = this.defaultMakesFilter;
        this.page = this.route.snapshot.queryParams.page ? parseInt(this.route.snapshot.queryParams.page, 10) : 1;
        this.skip = (this.page-1) * this.take;

        this.smallImageBreakpoints = [
            {
                breakpoint: StyleBreakpoints.Implicit,
                width: 360,
            },
            {
                breakpoint: StyleBreakpoints.Xsmall,
                width: 210,
            },
            {
                breakpoint: StyleBreakpoints.Small,
                width: 360,
            },
            {
                breakpoint: StyleBreakpoints.Medium,
                width: 360,
            },
            {
                breakpoint: StyleBreakpoints.Large,
                width: 360,
            },
            {
                breakpoint: StyleBreakpoints.Xlarge,
                width: 500,
            },
        ];

        this.largeImageBreakpoints = [
            {
                breakpoint: StyleBreakpoints.Xsmall,
                width: 420,
            },
            {
                breakpoint: StyleBreakpoints.Small,
                width: 750,
            },
            {
                breakpoint: StyleBreakpoints.Medium,
                width: 630,
            },
            {
                breakpoint: StyleBreakpoints.Large,
                width: 750,
            },
            {
                breakpoint: StyleBreakpoints.Xlarge,
                width: 865,
            },
        ];
    }

    ngOnInit() {
        this.transformArticleTags();
        this.setFiltersByUrlParams();
        this.getArticles();
    }

    ngAfterViewChecked() {
        if (this.articlesListElement) {
            this.currentArticlesListHeight = this.articlesListElement.nativeElement.clientHeight;
            if (this.currentArticlesListHeight !== this.articlesListHeight) {
                this.articlesListHeight = this.articlesListElement.nativeElement.clientHeight;
                this.cdr.markForCheck();
            }
        }
        this.afterFirstRender = true;
    }

    /**
     * Retrieves articles from the CMS based on the given parameters, and updates the local state.
     *
     * @remarks
     * This function is called when the component is initialized, and when the user requests more articles.
     * It fetches the next set of articles, and updates the local state with the new data.
     * It also generates a new URL based on the current filters and the new skip value.
     */
    getArticles() {
        const take = ARTICLE_ROWS_INCREMENT * 3;
        let category: string[] = [];
        let make: string[] = [];

        if (this.activeCategoryFilter.id !== this.defaultCategoryFilter.id) {
            category = [this.activeCategoryFilter.id];
        }

        if (this.activeMakesFilter.id !== this.defaultMakesFilter.id) {
            make = [this.activeMakesFilter.id];
        }

        this.contentService.getArticles(this.skip, take, category, make).subscribe((articlesData) => {
            // Merge new article with existing
            this.allArticleSpots = this.allArticleSpots.concat(articlesData.articles);

            this.allArticlesAmount = Number(articlesData.totalItems);
            this.showingArticlesAmount = this.allArticleSpots.length;
            this.skip = this.showingArticlesAmount;

            // Create article rows data
            this.allArticlesInSections = chunksArray(this.allArticleSpots, 3);
            this.fetchingArticles = false;

            this.generateNextUrl();
            this.cdr.markForCheck();
        });
    }

    /**
     * Transforms article tags from Umbraco API response to required format for the component.
     *
     * @remarks
     * The method takes the tags from the Umbraco API response and transforms them into the required format for the component.
     * The method creates two arrays: `articleCategoriesTagList` and `articleMakesTagList` which are used to render the tags in the component.
     */
    transformArticleTags() {
        // Article Category Tags
        this.articleCategoriesTagList = this.data?.articleCategoriesTagNavigation?.map((tag: Cms.GlobalTag) => {
            const cleanName = tag.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Normalizes and removes accents
            const key = cleanName.replace(/[^a-zA-Z0-9\s]/g, '').replaceAll(' ', '-').toLowerCase(); // Removes special characters, spaces to dashes, and lowercases

            return {
                displayName: tag.tagDisplayName || tag.name,
                id: tag.id,
                key: key,
                param: {
                    [ArticleFilterTypes.CATEGORY]: key,
                }
            };
        }) ?? [];

        // Article Make/Brand Tags
        this.articleMakesTagList = this.data?.vehicleMakesTagsNavigation?.map((tag: Cms.GlobalTag) => {
            const cleanName = tag.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Normalizes and removes accents
            const key = cleanName.replace(/[^a-zA-Z0-9\s]/g, '').replaceAll(' ', '-').toLowerCase(); // Removes special characters, spaces to dashes, and lowercases

            return {
                displayName: tag.tagDisplayName || tag.name,
                id: tag.id,
                key: key,
                param: {
                    [ArticleFilterTypes.MAKE]: key,
                }
            };
        }) ?? [];
    }


    /**
     * Generates the next url for the pagination.
     *
     * @remarks
     * The method takes the current query parameters and adds the page number incremented by 1.
     * The method then checks if the showing articles amount is less than the total articles amount.
     * If it is, the method sets the `nextUrl` property to the generated url, otherwise it sets it to undefined.
     */
    generateNextUrl() {
        const url = this.router.createUrlTree([], { queryParams: { ...this.route.snapshot.queryParams, page: this.page + 1 } })
        this.nextUrl = this.showingArticlesAmount < this.allArticlesAmount ? this.router.serializeUrl(url) : undefined;
    }

    /**
     * Handles the select change event for the article category and brand tags.
     *
     * @param tagId The id of the selected tag.
     * @param type The type of the selected tag, either 'category' or 'brand'.
     */
    filterSelectChange(tagId: string, type: string) {
        let selectedFilter: any;
        this.skip = 0;
        this.allArticleSpots = [];

        if (type === ArticleFilterTypes.CATEGORY) {
            selectedFilter = this.articleCategoriesTagList?.find((item: Cms.ArticleTag) => item.id === tagId)
        } else if (type === ArticleFilterTypes.MAKE) {
            selectedFilter = this.articleMakesTagList?.find((item: Cms.ArticleTag) => item.id === tagId)
        }

        if (selectedFilter) {
            this.filterArticles(selectedFilter);
        }
    }

    filterArticles(filterTag?: Tag) {
        this.skip = 0;
        this.page = 1;
        this.allArticleSpots = [];

        if (filterTag) {
            const { CATEGORY, MAKE } = ArticleFilterTypes;
            const { param } = filterTag;

            if (param[CATEGORY] && this.activeCategoryFilter.param[CATEGORY] !== param[CATEGORY]) {
                this.activeCategoryFilter = filterTag;
            } else if (param[MAKE] && this.activeMakesFilter.param[MAKE] !== param[MAKE]) {
                this.activeMakesFilter = filterTag;
            }

            this.setFilterUrlParams();
        }

        this.fetchingArticles = true;
        this.getArticles();
    }

    /**
     * Changes the URL parameters according to the active category and brand filters.
     *
     * If `params` is provided, it will be merged with the existing URL parameters.
     * Otherwise, the active category and brand filters will be serialized into the URL.
     *
     * This is used to update the URL after the user has changed the filter selection.
     * It is also used when the component is initialized to set the initial filter state from the URL.
     *
     * @param params The URL parameters to merge with the existing parameters. Optional.
     */
    setFilterUrlParams(params?: any) {
        const url = decodeURIComponent(this.router.url);

        // Set url parameters
        this.location.replaceState(
            this.router.serializeUrl(
                this.router.createUrlTree([url.split('?')[0]], {
                    /* Removed unsupported properties by Angular migration: skipLocationChange. */
                    queryParams: params
                        ? params
                        : Object.assign(this.activeCategoryFilter.param, this.activeMakesFilter.param),
                    relativeTo: this.route,
                    queryParamsHandling: 'merge',
                })
            )
        );
    }

    /**
     * Activate filters depending on url params
     *
     * Goes through the url query parameters and checks if the keys match
     * the category or brand filter types. If they do, it sets the active
     * category or brand filters to the tag with the matching key.
     * Then the getArticles() method will use these parameters to fetch articles according to the active filters.
     * Called when the component is initialized and when the url changes.
     */
    setFiltersByUrlParams(): void {
        const params = this.route.snapshot.queryParams;

        for (const [key, value] of Object.entries(params)) {
            if (typeof params[key] !== 'undefined' && params[value] !== '') {
                if (key === ArticleFilterTypes.CATEGORY && this.articleCategoriesTagList) {
                    for (const tag of this.articleCategoriesTagList) {
                        if (params[key] === tag.key || params[key][0] === tag.key) {
                            this.activeCategoryFilter = tag;
                        }
                    }

                    // if none is selected, set the default
                    if (!this.activeCategoryFilter) {
                        this.activeCategoryFilter = this.defaultCategoryFilter;
                    }
                }
                if (key === ArticleFilterTypes.MAKE && this.articleMakesTagList) {
                    for (const tag of this.articleMakesTagList) {
                        if (params[key] === tag.key || params[key][0] === tag.key) {
                            this.activeMakesFilter = tag;
                        }
                    }
                    // if none is selected, set the default
                    if (!this.activeMakesFilter) {
                        this.activeMakesFilter = this.defaultMakesFilter;
                    }
                }
            }
        }
    }

    trackById(index: number, item: any) {
        return item.id || index;
    }

    /**
     * Increments page number and fetches more articles.
     * Then sets query parameters in the URL based on the active filters.
     * @returns void
     */
    showMoreArticles(): void {
        this.page = this.page + 1;
        this.fetchingArticles = true;
        this.getArticles();
        this.setFilterUrlParams();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
@NgModule({
    declarations: [ArticlesOverviewPageComponent],
    imports: [
        TranslateModule.forChild(),
        SpotsUiModule,
        CommonModule,
        BrowserModule,
        RouterModule,
        RichTextModule,
        UtilsModule,
        SectionHeaderModule,
        NavigationModule,
        LoadMoreModule,
        FormElementsModule,
        ComponentLoaderModule,
        SpotsUiModule
    ],
})
class ArticlesOverviewPageModule {}

export const LazyComponent = ArticlesOverviewPageComponent;
