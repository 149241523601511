import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILink } from '@impact/data';
import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';

@Component({
    selector: 'app-load-more',
    template: `
        <div class="load-more">
            <!-- {{ 'filterpage.load_more____progress_text' | translate: { shownCars: shownItems, totalCars: totalItems } }} -->
            {{statusText}}
            <div *ngIf="!hideProgress" class="load-more__progress">
                <div
                    class="load-more__to-load"
                    [style.transform]="'translateX(-' + (100 - (showToLoadInset || loading ? toLoadInset : loadedInset)) + '%)'"
                ></div>
                <div class="load-more__loaded" [style.transform]="'translateX(-' + (100 - loadedInset) + '%)'"></div>
            </div>

            <app-button *ngIf="shownItems !== totalItems && link"
                [hidden]="!isServer"
                [title]="buttonText"
                [link]="link"
            ></app-button>

            <app-button *ngIf="!isServer"
                (handleClick)="handleClick.emit($event)"
                (mouseenter)="showToLoadInset = true"
                (mouseleave)="showToLoadInset = false"
                buttonClass="button--primary"
                [loading]="!!loading"
                [hidden]="shownItems === totalItems"
                [title]="buttonText"
                [disabled]="disabled ?? false"
            ></app-button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadMoreComponent implements OnInit {
    @Input() shownItems?: number;
    @Input() totalItems?: number;
    @Input() incrementSize?: number;
    @Input() statusText: string;
    @Input() buttonText: string;
    @Input() loading?: boolean;
    @Input() hideProgress?: boolean;
    @Input() disabled?: boolean;
    @Input() loadMoreUrl?: string;
    @Output() handleClick = new EventEmitter();

    link?: ILink;
    showToLoadInset = false;
    isServer: boolean;

    /**
     *
     */
    constructor(private featureDetectionService: FeatureDetectionService) {
        this.isServer = this.featureDetectionService.isServer();
    }

    ngOnInit() {
        this.link = this.loadMoreUrl ? {
            isExternal: false,
            name: this.buttonText,
            url: this.loadMoreUrl,
        } : undefined;
    }

    get loadedInset() {
        return ((this.shownItems || 0) / (this.totalItems || 0)) * 100;
    }
    get incrementInset() {
        return ((this.incrementSize || 0) / (this.totalItems || 0)) * 100;
    }
    get toLoadInset() {
        return (this.loadedInset || 0) + (this.incrementInset || 0) > 100 ? 100 : (this.loadedInset || 0) + (this.incrementInset || 0);
    }
}
