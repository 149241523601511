import { Injectable } from '@angular/core';
import { map, shareReplay, take } from 'rxjs/operators';
import { ContentService } from '../content/content.service';

@Injectable({
    providedIn: 'root',
})
export class GlobalDataService {
    private globalData$ = this.contentService
        .getGlobalData()
        .pipe(shareReplay({ bufferSize: 1, refCount: false }));

    constructor(private contentService: ContentService) {}

    getGlobalCampaigns() {
        return this.globalData$.pipe(
            take(1),
            map((g) => g.globalCampaigns)
        );
    }

    getDealerships() {
        return this.globalData$.pipe(
            take(1),
            map((g) => g.dealerships)
        );
    }

    getBanks() {
        return this.globalData$.pipe(
            take(1),
            map((g) => g.banks)
        );
    }
}
