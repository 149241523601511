import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { SidePanelService, SidePanelSizes } from '../../side-panel/side-panel.service';

@Directive({
  selector: '[appInterceptLinks]',
})
export class InterceptLinksDirective {
    @Input() dialogId: string;

    isSidepanelLink: boolean;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly router: Router,
        private readonly viewportScroller: ViewportScroller,
        private readonly sidePanelService: SidePanelService
    ) {}

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): boolean {
        let linkEle = event.target as HTMLElement;

        // Traverse up until we find an anchor element or reach the root element
        while (linkEle && linkEle.tagName !== 'A') {
            if (linkEle === this.elementRef.nativeElement) {
                return true;  // Clicked outside of an <a> tag
            }
            linkEle = linkEle.parentElement as HTMLElement;
        }

        // Proceed if a valid anchor element is found
        if (linkEle && (linkEle as HTMLAnchorElement).href) {
            const anchor = linkEle as HTMLAnchorElement;

            // Ignore links meant for download or specific protocols
            if (anchor.hasAttribute('download') || ['mailto:', 'tel:'].some(protocol => anchor.href.startsWith(protocol))) {
                return true;
            }

            // If the link is set to open in a new tab/window, allow default behavior
            if (anchor.target === '_blank') {
                return true;
            }

            // Check for #sidepanel in href hash or data-anchor attribute
            const href = decodeURIComponent(linkEle.getAttribute('href') ?? '');
            const url = new URL(href, window.location.origin);
            const dataAnchor = anchor.getAttribute('data-anchor');

            if (url.hash === '#sidepanel' || url.href.includes('#sidepanel') || dataAnchor === '#sidepanel') {
                const cleanUrl = url.pathname.replace(/\/+$/, '');

                // Find correct overlay component
                let size = SidePanelSizes.LARGE;

                if (url.pathname?.includes('medium')) {
                    size = SidePanelSizes.SMALL;
                }

                if (url.pathname?.includes('small')) {
                    size = SidePanelSizes.SMALL;
                }

                this.sidePanelService.getSidepanelContentAndOpen(cleanUrl, size);
                event.preventDefault();
                return false;
            }

            // Otherwise, handle navigation using the router
            this.navigate(url);
            event.preventDefault();
            return false;
        } else {
            return true;  // Allow default behavior if no <a> tag is involved
        }
    }

    private navigate(url: URL): void {
        // Exit and follow link if link is external
        if (url.host !== window.location.host) {
            window.location.href = url.href;  // Navigate to external link
            return;
        }

        // If the path and fragment are the same, do nothing
        if (url.pathname === window.location.pathname && url.hash) {
            return;
        }

        const extras: NavigationExtras = {
            fragment: url.hash ? url.hash.substring(1) : undefined,
            queryParams: this.parseQueryParams(url.search),
        };

        // If interceptLink is used in an overlay
        if (url.hash && url.pathname === '/' && extras.fragment) {
            if (this.dialogId) {
                this.scrollToAnchorInDialog(extras.fragment);
                return;
            }
            if (url.hash === window.location.hash) {
                this.viewportScroller.scrollToAnchor(extras.fragment);
            } else {
                this.router.navigate([], extras);
            }
        } else {
            this.router.navigate([url.pathname], extras);
        }
    }

    private scrollToAnchorInDialog(anchorId: string): void {
        if (!this.dialogId) return;

        const dialogElement = document.getElementById(this.dialogId);
        if (dialogElement) {
            const targetElement = document.getElementById(anchorId) || document.getElementsByName(anchorId)[0];
            targetElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    private parseQueryParams(searchString: string): { [key: string]: string | string[] } {
        const params: { [key: string]: string | string[] } = {};
        const query = new URLSearchParams(searchString);

        query.forEach((value, key) => {
        if (params[key]) {
            if (Array.isArray(params[key])) {
                (params[key] as string[]).push(value);
            } else {
                params[key] = [params[key] as string, value];
            }
        } else {
            params[key] = value;
        }
        });

        return params;
    }
}
