import { Injectable } from '@angular/core';
import { Cms } from '@impact/data';
import { BehaviorSubject } from 'rxjs';
import {
    BODY_CLASS,
    FeatureDetectionService,
} from '../../utils/helpers/feature-detection.service';

const bodyClass = BODY_CLASS.MEGA_NAVIGATION_ACTIVE;

export interface ShowMegaNavigation {
    isActive: boolean;
    item: Cms.NavigationDto | undefined;
}

@Injectable({
    providedIn: 'root',
})
export class MegaNavigationService {
    private showMegaNavigationSubject$ =
        new BehaviorSubject<ShowMegaNavigation>({
            isActive: false,
            item: undefined,
        });

    showMegaNavigation$ = this.showMegaNavigationSubject$.asObservable();

    constructor(private featureDetectionService: FeatureDetectionService) {}

    showMegaNavigation(isActive: boolean, item?: Cms.NavigationDto) {
        if (isActive) {
            this.featureDetectionService.addBodyClass(bodyClass);
        } else {
            this.featureDetectionService.removeBodyClass(bodyClass);
        }

        this.showMegaNavigationSubject$.next({
            isActive,
            item: isActive ? item : undefined,
        });
    }
}
