import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { FeatureDetectionService, PageBusyService, ScrollRestorationService } from '@impact/ui';
import { OverlayService } from '@impactdk/ngx-overlay';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    template: `
        <app-json-ld></app-json-ld>

        <app-notifications-bar></app-notifications-bar>
        <app-site-backdrop></app-site-backdrop>
        <app-site-header></app-site-header>

        <div class="router-outlet">
            <router-outlet></router-outlet>
        </div>
        <app-seo-footer></app-seo-footer>
        <app-footer></app-footer>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    lastRouterYPos: number | null;

    constructor(
        private translateService: TranslateService,
        private featureDetectionService: FeatureDetectionService,
        private router: Router,
        private overlayService: OverlayService,
        private pageBusyService: PageBusyService,
        private scrollRestoration: ScrollRestorationService
    ) {}

    ngOnInit() {
        // Page transition
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.overlayService.closeAll();
                this.pageBusyService.isPageBusy$.next(true);
            }
        });

        this.scrollRestoration.initScrollRestorationHelper();

        this.translateService.use('platform');

        this.featureDetectionService.devTools(environment.production);
        this.featureDetectionService.appReady();
        this.featureDetectionService.browserSpecificHacks();
    }
}
