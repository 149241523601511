import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Cms, PAGE_TYPES } from '@impact/data';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { carImageFallbackIcon, chevronRight } from '../../../../icons';
import { NavigationService } from '../../navigation.service';
import { MegaNavigationService } from '../mega-navigation.service';

const vehicleMakePage = PAGE_TYPES.VEHICLE_MAKE_PAGE;
const vehicleModelPage = PAGE_TYPES.VEHICLE_MODEL_PAGE;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-mega-navigation-new-cars',
    templateUrl: './mega-navigation-new-cars.component.html',
})
export class MegaNavigationNewCarsComponent implements OnInit, OnDestroy {
    private unsubscribe = new Subject<void>();

    @Input() data: Cms.NavigationDto;
    @ViewChild('newCarsContainer') newCarsContainer: ElementRef;

    activeMakeNode: Cms.NavigationDto | undefined;
    makeNodes: Cms.NavigationDto[] = [];
    carImageFallbackIcon = carImageFallbackIcon;
    chevronRight = chevronRight;
    filteredModelNodes: Cms.NavigationDto[] = [];
    isB2B$ = this.navigationService.isB2B$;
    isReady: boolean;
    vehicleTypes: Cms.UmbracoTagDto[] = [];

    defaultFilter: Cms.UmbracoTagDto = {
        displayName: this.translateService.instant(
            'car_data.vehicle_type_filter_button_all'
        ),
        id: 'default',
    };

    activeVehicleType = this.defaultFilter;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private megaNavigationService: MegaNavigationService,
        private navigationService: NavigationService,
        private translateService: TranslateService
    ) {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit() {
        this.makeNodes = this.data.children.filter(
            (c) =>
                !c.hideInMenu &&
                c.includeInNavigation &&
                c.template === vehicleMakePage
        );

        this.navigationService.activeBrand$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((activeBrand) => {
                if (!activeBrand) {
                    this.setActiveMake(this.makeNodes[0]);
                    return;
                }

                const makeNode = this.makeNodes.find(
                    (c) => c.vehicleBrandData?.brandName === activeBrand
                );

                // If no make node is found, set the first make node as active.
                if (!makeNode) {
                    this.setActiveMake(this.makeNodes[0]);
                    return;
                }

                this.updateFromMakeNode(makeNode);
            });
    }

    closeMegaNavigation() {
        this.megaNavigationService.showMegaNavigation(false);
    }

    setActiveMake(make: Cms.NavigationDto) {
        this.navigationService.setActiveBrand(
            make.vehicleBrandData?.brandName
        );
    }

    setModelFilter(vehicleType: Cms.UmbracoTagDto) {
        this.activeVehicleType = vehicleType;

        const modelNodes =
            this.activeMakeNode?.children.filter(
                (c) =>
                    !c.hideInMenu &&
                    c.includeInNavigation &&
                    c.template === vehicleModelPage
            ) ?? [];

        if (this.activeVehicleType.id === this.defaultFilter.id) {
            // Find all navigation nodes.
            this.filteredModelNodes = modelNodes;
        } else {
            // Find all navigation nodes matching on vehicle type.
            this.filteredModelNodes = modelNodes.filter((c) => {
                return c.vehicleModelData.vehicleTypes.some(
                    (t) => t.id === this.activeVehicleType?.id
                );
            });
        }

        this.changeDetectorRef.markForCheck();
    }

    private findChildVehicleTypes(makeNode: Cms.NavigationDto | undefined) {
        if (!makeNode) return [];

        return makeNode.children.reduce<Cms.UmbracoTagDto[]>((a, modelNode) => {
            modelNode.vehicleModelData?.vehicleTypes.forEach((type) => {
                if (!a.some((addedType) => addedType.id === type.id)) {
                    a.push(type);
                }
            });

            return a;
        }, []);
    }

    private updateFromMakeNode(makeNode: Cms.NavigationDto | undefined) {
        if (!makeNode) return;

        this.activeMakeNode = makeNode;

        this.vehicleTypes = [
            this.defaultFilter,
            ...this.findChildVehicleTypes(makeNode),
        ];

        this.setModelFilter(this.defaultFilter);

        setTimeout(() => {
            this.isReady = true;
            this.changeDetectorRef.markForCheck();
        }, 350);
    }
}
