<div class="mega-navigation-layout-wrapper">
    <div class="mega-navigation-layout-wrapper-inner">
        <div class="mega-navigation-content-layout" [ngClass]="{'is-ready': isReady}">
            <div class="mega-navigation__content mega-navigation-new-cars__content">
                <!-- LEFT COLUMN -->
                <div class="mega-navigation__content-column-left">
                    <div class="mega-navigation__content-header" [ngClass]="{'is-ready': isReady}">
                        <h2 class="mega-navigation-spot-layout__content-header-text">{{ data.name }}</h2>
                    </div>

                    <ul class="mega-navigation__navigation-list">
                        <li class="mega-navigation__navigation-list-item" *ngFor="let make of makeNodes">
                            <button class="mega-navigation__navigation-list-link"
                                [ngClass]="{ 'is-active': activeMakeNode?.name === make.name }"
                                (mouseover)="setActiveMake(make)">
                                {{make.name}}
                            </button>
                            <div class="mega-navigation__navigation-active-indicator" innerHTML="chevronRight"></div>
                        </li>
                    </ul>

                    <ul class="mega-navigation__navigation-list mega-navigation__navigation-list--secondary" *ngIf="data.megaNavigationNewCarsOptionalMenu">
                        <li *ngFor="let link of data.megaNavigationNewCarsOptionalMenu.links">
                            <a [routerLink]="link.url" class="mega-navigation__navigation-list-link">
                                {{link.name}}
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- RIGHT COLUMN -->
                <div class="mega-navigation__content-column-right">
                    <header class="mega-navigation-new-cars__content-header">
                        <h2 class="mega-navigation-spot-layout__content-header-text">{{ activeMakeNode?.name }}</h2>
                        <a [routerLink]="activeMakeNode?.url" class="button--primary">{{'car_data.see_all_from_brand' | translate}} {{activeMakeNode?.name}}</a>
                    </header>

                    <!-- Model Filter -->
                    <ul class="new-cars-card-list__filter" *ngIf="vehicleTypes.length">
                        <li class="new-cars-card-list__filter-item" *ngFor="let filter of vehicleTypes">
                            <button
                                class="button--minimal new-cars-card-list__filter-button"
                                [class.active-item]="activeVehicleType.id === filter.id"
                                [innerText]="filter.displayName"
                                (click)="setModelFilter(filter)"
                            ></button>
                        </li>
                    </ul>

                    <!-- NEW CARS CONTENT -->
                    <div class="mega-navigation__scroll-container" #newCarsContainer>
                        <ul class="mega-navigation-new-cars__content-list">
                            <ng-container *ngFor="let item of filteredModelNodes">
                                <li class="mega-navigation-new-cars__content-list-item">
                                    <a [routerLink]="item.url" class="mega-navigation-new-cars__content-list-item-wrapper" (click)="closeMegaNavigation()">
                                        <ng-container [ngSwitch]="!!item.vehicleModelData.modelDisplayImage.src">
                                            <img
                                                appImageLoadFadeIn
                                                class="mega-navigation-new-cars__content-list-item-image"
                                                *ngSwitchCase="true"
                                                [src]="item.vehicleModelData.modelDisplayImage.src | resolveMediaUrl: {width: 250, format: 'webp'}"
                                            />
                                            <div
                                                *ngSwitchCase="false"
                                                class="mega-navigation-new-cars__content-list-item-fallback-image"
                                                [innerHTML]="carImageFallbackIcon"
                                            ></div>
                                        </ng-container>
                                        <div class="mega-navigation-new-cars__content-list-item-info">
                                            <div class="model-name">
                                                {{item.vehicleModelData?.modelName}}
                                            </div>
                                            <div class="model-price" *ngIf="item.vehicleModelData?.startPrice">
                                                {{'car_data.starting_price_from_short' | translate}} {{item.vehicleModelData?.startPrice | defaultcurrency}}
                                                <span class="vat-price" *ngIf="isB2B$ | async">{{'generic.vat_excluded' | translate}}</span>
                                            </div>
                                        </div>
                                    </a>

                                    <div *ngIf="item.activeCampaign" class="campaign-tag">
                                        {{'car_data.campaign_label_text' | translate}}
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>