import { IAnchorNavigationItem } from './anchor-navigation.interface';
import { IGridContent } from './generic/grid.interface';
import { IImage } from './generic/image.interface';
import { ILink } from './generic/link.interface';
import { IPageBase } from './generic/page-base.interface';
import { IPageBreadcrumb } from './generic/page-breadcrumb.interface';
import { IVehicleBrand, IVehicleModel } from './global-data-response.interface';
import { INavigationResponse } from './navigation-response.interface';

interface IPageMeta {
    readonly title?: string;
    readonly description?: string;
    readonly keywords?: string;
    readonly canonical?: string;
    readonly image?: IImage;
    readonly excludeFromRobots: boolean;
    readonly includeInSearch: boolean;
    readonly includeInNavigation: boolean;
    readonly includeInFooter: boolean;
    readonly hideInMenu: boolean;
}

export enum PAGE_TYPES {
    HOME_PAGE = 'frontPage',
    ARTICLES_OVERVIEW_PAGE = 'articlesOverviewPage',
    ARTICLE_PAGE = 'articlePage',
    B2B_SECTION_PAGE = 'b2bSectionPage',
    BOOK_WORKSHOP_PAGE = 'bookWorkshopPage',
    CAMPAIGN_PAGE = 'campaignPage',
    CHECKOUT_PAGE = 'checkoutPage',
    LEASING_CHECKOUT_PAGE = 'leasingCheckoutPage',
    DEALERSHIP_PAGE = 'dealershipPage',
    DEALERSHIPS_OVERVIEW_PAGE = 'dealershipsOverviewPage',
    ERROR_PAGE = 'errorPage',
    GRID_LAYOUT_PAGE = 'gridLayoutPage',
    LEFT_NAVIGATION_GRID_LAYOUT_PAGE = 'leftNavigationGridLayoutPage',
    LINK_PAGE = 'linkPage',
    NAVIGATION_SECTION_PAGE = 'navigationSectionPage',
    NEW_VEHICLES_OVERVIEW_PAGE = 'newVehiclesOverviewPage',
    ORDER_CONFIRMATION_PAGE = 'orderConfirmationPage',
    USED_CARS_DETAILS_PAGE = 'usedCarDetailsPage',
    USED_CARS_OVERVIEW_PAGE = 'usedCarsOverviewPage',
    VEHICLE_MAKE_PAGE = 'newVehiclesMakePage',
    VEHICLE_MODEL_PAGE = 'newVehiclesModelPage',
    VEHICLE_MODEL_SUBPAGE = 'newVehiclesModelSubPage',
    VEHICLE_LEASING_OVERVIEW_PAGE = 'vehicleLeasingOverviewPage',
    VEHICLE_LEASING_MAKE_PAGE = 'vehicleLeasingMakePage',
    VEHICLE_LEASING_MODEL_PAGE = 'vehicleLeasingModelPage',
    VEHICLE_VARIANT_PAGE = 'vehicleVariantPage',
    SIDEPANEL = 'sidepanel',
    SIDEPANEL_RICH_TEXT = 'sidepanelRichText',
    NOT_FOUND_PAGE = 'notFoundPage',
    FILTER_PAGE = 'filterPage',
}

interface IPageResponseBase extends IPageBase {
    readonly meta: IPageMeta;
    readonly content: IPageContent;
    readonly breadcrumbs: IPageBreadcrumb[];
    isB2BPage: boolean;
    readonly transparentSiteHeader: boolean;
}

interface IPageContent {
    [key: string]: any;
    grid?: IGridContent;
    topGrid?: IGridContent;
    bottomGrid?: IGridContent;
}


export interface ILeftNavigationGridLayoutPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.LEFT_NAVIGATION_GRID_LAYOUT_PAGE;
}

export interface IUsedCarDetailsPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.USED_CARS_DETAILS_PAGE;
    disclaimerSpecificationsText: string;
    galleryCtaLink: ILink;
    disclaimerFinancingText: string;
    usp: any[];
    financingHeader: string;
    usedCarId: string;
    notForSaleText: string;
    bookTestDriveCtaCategoryHeaderText?: string;
    bookTestDriveCtaHeaderText?: string;
    bookTestDriveCtaText?: string;
    leasingInfo?: string;
    insuranceSectionIntroText?: string;
    insuranceGuidePageLink?: ILink;
    insuranceSectionDisclaimerText?: string;
    cashOptionInfoLink?: ILink;
    loanOptionInfoLink?: ILink;
    leasingOptionInfoLink?: ILink;
    leasingTermsLink?: ILink;
    contentSpotsGrid1?: IGridContent;
    contentSpotsGrid2?: IGridContent;
}

export interface IUsedCarOverviewPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.USED_CARS_OVERVIEW_PAGE;
    title: string;
    description: string;
    initialFilterFacetList?: string;
    prettyUrlParams?: string;
    productGridSpots: any[];
    productGridSpotsInterval: number;
    productGridSpotsStartingPosition: number;
    productGridSpotsRepeat: boolean;
    showLeasingPricesOnly: boolean;
}

export interface IVehicleModelPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.VEHICLE_MODEL_PAGE;
    vehicleModelData: any;
    children?: INavigationResponse[] | undefined;
    parentUrl?: string;
    topBanner?: any;
    showTopBanner?: boolean;
    displaySmallBanner?: boolean;
    showTestDriveButton?: boolean;
}

export interface IVehicleModelSubPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.VEHICLE_MODEL_SUBPAGE;
    vehicleModelData: any;
    children?: INavigationResponse[] | undefined;
    parentUrl?: string;
    topBanner?: any;
    showTopBanner?: boolean;
    displaySmallBanner?: boolean;
}

export interface IVehicleLeasingBrandPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.VEHICLE_LEASING_MAKE_PAGE;
    vehicleBrandData: IVehicleBrand;
    topBanner?: any;
    showTopBanner?: boolean;
    newCarsSpotData?: any;
    newCarsBrand: IVehicleBrand;
    newCarsLayoutMode?: string;
    newCarsModelExclusion?: IVehicleModel;
    displaySmallBanner?: boolean;
}

export interface IVehicleLeasingModelPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.VEHICLE_LEASING_MODEL_PAGE;
    vehicleModelData: any;
    pageGridAnchorNavigationList: IAnchorNavigationItem[];
    children?: INavigationResponse[] | undefined;
    parentUrl?: string;
    topBanner?: any;
    showTopBanner?: boolean;
    displaySmallBanner?: boolean;
}

export interface IBookWorkshopPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.BOOK_WORKSHOP_PAGE;
    introduction: string;
    bookingSuccess: string;
    externalBookingOptional?: string;
    externalBookingOnly?: string;
    externalBookingButtonStayOnSite?: string;
    externalBookingButtonGoToExternalFlow?: string;
    rentalCarImages?: any[];
    deliveryOptionsInfoTexts: any[];
    datePickerRentalInformationText?: string;
    continueWithoutRentalText?: string;
    summaryCommentInfoText?: string;
}

export type PageResponse =
    | IBookWorkshopPageResponse
    | IUsedCarDetailsPageResponse
    | IUsedCarOverviewPageResponse
    | IVehicleModelPageResponse
    | IVehicleLeasingBrandPageResponse
    | IVehicleLeasingModelPageResponse
    | IPageResponseBase
    | ILeftNavigationGridLayoutPageResponse;
