import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Cms, INavigationResponse, TempAny } from '@impact/data';

import { mobileAnimation, MobileAnimationState } from '../../utils/animations/mobile-menu.animations';

@Component({
    selector: 'app-mobile-default-nav',
    template: `
        <div [@slideContent]="animationState">
            <div class="mobile-menu__section-header">
                <h1>{{ navigation.name }}</h1>
            </div>

            <div class="mobile-menu__scroll-container mobile-menu__scroll-container--sub-header">
                <app-mobile-nav-list
                    *ngFor="let item of navigation.children"
                    [navigationChildren]="item.children"
                    [heading]="item.name"
                    [headingUrl]="item.url"
                ></app-mobile-nav-list>

                <!-- NAVIGATION SPOT -->
                <div class="mobile-menu__spot-wrapper" *ngIf="navigation.megaNavigationSpot">
                    <app-mega-navigation-spot [data]="navigation.megaNavigationSpot"></app-mega-navigation-spot>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [mobileAnimation],
})
export class MobileDefaultNavComponent {
    // @Input() navigation: Cms.NavigationDto;
    @Input() navigation: TempAny // TODO: Cms.NavigationDto;
    @Output() navigate = new EventEmitter<Cms.NavigationDto>();

    animationState: MobileAnimationState = 'enter';
}
