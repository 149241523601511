import { IImage } from './generic/image.interface';
import { ILink } from './generic/link.interface';

interface IColorPicker {
    color: string;
    label: string;
}

export interface BrandTheme {
    primaryColor: IColorPicker;
    secondaryColor: IColorPicker;
}

interface Employee {
    employeeDepartment: string;
    departmentName?: string;
    employeeEmail: string;
    employeeImage: IImage | undefined;
    employeeName: string;
    employeePhoneNumber: number;
    jobTitle: string;
}

export interface IDepartment {
    departmentName: string;
    departmentType: string;
    weeklyOpeningHours: string[];
    openingHours?: IOpeningHours[];
    specialOpeningHours?: IBusinessDay[];
    nextSevenDays?: IOpeningHours[];
    isOpenNow?: boolean;
    openingHoursToday?: any[];
}

export enum OpeningHoursDays {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday',
}

interface IBusinessDay {
    date?: string;
    isOpen?: boolean;
    hoursOfBusiness?: IHoursOfBusiness[];
}

export interface IOpeningHours extends IBusinessDay {
    dayOfTheWeek?: OpeningHoursDays;
}

export interface IHoursOfBusiness {
    opensAt?: string;
    closesAt?: string;
}

export interface IDealership extends IGlobalDataResponseBase {
    aboutDealershipText: string;
    aboutDealershipTitle: string;
    address: string;
    autoDesktopId: string;
    city: string;
    contactEmail: string;
    contactPhoneNumber: number;
    dealershipBannerImage: IImage;
    dealershipID: string;
    departments: IDepartment[];
    descriptionShort: string;
    displayAmount?: number;
    displayName: string;
    dracarID?: string;
    employees: Employee[];
    lastSlideLink: ILink;
    lastSlideText: string;
    layoutMode?: string;
    locationID: string;
    locationLatitude: string;
    locationLongitude: string;
    pageLink?: ILink;
    usedCarsHeader?: string;
    usedCarsHeaderLink?: ILink;
    usedCarsQuery: string;
    vehicleBrands?: IVehicleBrand[];
    serviceBrands?: IServiceBrand[];
    zipCode: string;
    rentlogID?: string;
}

export interface IGlobalVehicleModelCardList {
    modelName: string;
    modelId: string;
    models: any[];
}

interface IGlobalDataResponseBase {
    contentTypeAlias: string;
    createDate?: string;
    hasChildren?: boolean;
    id: string;
    name: string;
    parentId: string;
    sortOrder?: number;
    updateDate?: string;
}

export interface IVehicleBrand extends IGlobalDataResponseBase {
    children?: IVehicleModel[];
    logo: IImage;
    vehicleTypes: any[];
    brandTheme: BrandTheme;
    activeCampaigns?: any[];
}

export interface IServiceBrand extends IGlobalDataResponseBase {
    logo?: IImage;
    brandTag?: IBrandTag;
    externalFlowLink?: string | [];
    forceExternalFlow?: boolean;
}

interface IBrandTag extends IGlobalDataResponseBase {
    displayName?: string;
}

export interface IVehicleModel extends IGlobalDataResponseBase {
    children?: IVehicleVariant[];
    modelName: string;
    modelDisplayImage: IImage;
    energyRating: any[]; // Energy rating array
    exteriorImages: IImage[];
    interiorImages: IImage[];
    modelDescriptionLong: string;
    modelDescriptionShort: string;
    name: string;
    id: string;
    vehicleType: any[]; // Type array
    startPrice: number;
    brandTheme: BrandTheme;
    activeCampaigns: any[];
    starsRating?: number;
    adultOccupantRating?: number;
    childOccupantRating?: number;
    pedestrianRating?: number;
    safetyAssistRating?: number;
    fuelConsumption?: string;
    co2EmissionLevels?: string;
}

interface IVehicleVariant extends IGlobalDataResponseBase {
    accessoriesListHeader: string;
    accessoriesList: string[];
    activeCampaigns: any[];
    brandTheme: BrandTheme;
    introText: string;
    isPopularChoice: boolean;
    kilometersPerYear: number;
    leasingDeposit: number;
    leasingDetailsLink: ILink;
    leasingDetailsSecondaryLink?: ILink;
    leasingOfferTotalPrice?: number;
    leasingPrice?: number;
    leasingValidUntil?: string;
    loanMaturity?: number;
    brandName?: string;
    modelName?: string;
    priceListLink?: ILink;
    showGetOfferButton: boolean;
    showPriceCheckButton: boolean;
    showTestDriveButton: boolean;
    startPrice: number;
    variantName: string;
    vehicleImage?: IImage;
    vehicleType: any[];

    showOnBNDk: boolean;
    showOnBilernesHusDk: boolean;
    showOnLeasingDk: boolean;
    showLeasing: boolean;

    // Leasing
    leasingApiCarDetailsData?: any;
    leasingApiIntervalData?: any;
    leasingMakeName?: string;
    leasingModelName?: string;
    leasingModelDescription?: string;
    leasingModelDescriptionDisplayName?: string;
}

export interface IDealerShips extends IGlobalDataResponseBase {
    children?: IDealership[];
}
