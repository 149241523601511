import { Injectable } from '@angular/core';
import { Cms } from '@impact/data';
import { BehaviorSubject } from 'rxjs';
import { IMobileMenu, MobileMenuTypes } from './mobile-menu';

@Injectable({
    providedIn: 'root',
})
export class MobileNavigationService {
    private breadcrumbs: IMobileMenu[] = [];
    private currentMenuSubject$ = new BehaviorSubject<IMobileMenu | undefined>(
        undefined
    );

    currentMenu$ = this.currentMenuSubject$.asObservable();

    navigateByTemplate(navigation: Cms.NavigationDto, template: string) {
        let type: MobileMenuTypes = MobileMenuTypes.Default;

        switch (template) {
            case 'newVehiclesOverviewPage':
            case 'vehicleLeasingOverviewPage':
            case 'newCarsPage':
                type = MobileMenuTypes.BrandsOverview;
                break;
            case 'usedCarsOverviewPage':
                type = MobileMenuTypes.UsedCarsOptions;
                break;
            case 'articlesOverviewPage':
                type = MobileMenuTypes.Articles;
                break;
        }

        this.navigateByObject({
            type,
            navigation,
        });
    }

    navigateByObject(item: IMobileMenu) {
        this.currentMenuSubject$.next(item);
        this.breadcrumbs.push(item);
    }

    back() {
        if (this.breadcrumbs.length) {
            this.breadcrumbs.pop();
            this.currentMenuSubject$.next(
                this.breadcrumbs[this.breadcrumbs.length - 1]
            );
        } else {
            this.currentMenuSubject$.next(undefined);
        }
    }
}
