import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

import { MetaService } from '../meta/meta.service';
import { transformRichTextMedia } from '../utils/helpers/media-helpers';
import { Environment, ENVIRONMENT } from '../environment/environment';

@Component({
    selector: 'app-rich-text',
    template: `<div *ngIf="html" class="rich-text" [ngClass]="cssClasses" [innerHTML]="html | safe: 'html'" appInterceptLinks></div>`,
})
export class RichTextComponent implements OnInit, OnDestroy {
    @Input() html?: string | SafeHtml | null;
    @Input() cssClasses: string;
    @Input() preLoadImages = false;

    preConnectImages?: any[];

    constructor(
        @Inject(ENVIRONMENT) private environment: Environment,
        private metaService: MetaService
    ) {}

    ngOnInit() {
        if (!this.html) {
            return;
        }

        // Transform rich text img tag url's
        this.html = transformRichTextMedia(String(this.html), this.environment);

        if (this.html && this.preLoadImages) {
            this.preConnectImages = [];

            // Find images in Rich Text and add them to preload in head
            const imgRex = /<img.*?src="(.*?)"[^>]+>/g;
            const images: string[] = [];
            let img;

            while ((img = imgRex.exec(String(this.html)))) {
                images.push(img[1]);
            }

            if (images.length) {
                this.preConnectImages = [];
                this.preConnectImages = images.map((image) => this.metaService.setPreloadResource(String(image), 'image'));
            }
        }
    }

    ngOnDestroy() {
        if (this.preConnectImages?.length) {
            for (let i = 0; i < this.preConnectImages.length; i++) {
                this.preConnectImages[i].remove();
            }
        }
    }
}
