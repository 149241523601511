import { Directive, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
  selector: '[appColonSplit]'
})
export class ColonSplitDirective {
  @Input() appColonSplit: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    if (this.appColonSplit) {
      const parts = this.appColonSplit.split(':');
      if (parts.length === 2) {
        // If there is a colon, content before stays bold
        const formattedText = `
          ${parts[0]}: <span class="heading-regular">${parts[1]}</span>`;
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', formattedText);
      } else {
        // If no colon, contentet stays the same
        this.renderer.addClass(this.el.nativeElement, 'heading-regular');
      }
    }
  }
}
