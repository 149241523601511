import { Component, ComponentFactoryResolver, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PAGE_TYPES } from '@impact/data';
import { HeaderService } from '../core/header.service';
import { MetaService } from '../meta/meta.service';
import { ImageUrl } from '../utils/helpers/image-helper';
import { TrackingService } from '../utils/tracking/tracking.service';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { PageBusyService } from './page-busy.service';
import { getLazyPageComponent } from './page-components';

const fallbackComponent = {
    LazyComponent: NotFoundPageComponent,
};

interface PageComponentData<T = unknown> {
    data?: T;
}

const disallowTrackingTypes = [
    PAGE_TYPES.USED_CARS_DETAILS_PAGE,
    PAGE_TYPES.USED_CARS_OVERVIEW_PAGE,
];

type PageResponse = any;

@Component({
    selector: 'app-page',
    template: '<ng-template></ng-template>',
})
export class PageComponent implements OnInit {
    @ViewChild(TemplateRef, { static: true, read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly headerService: HeaderService,
        private readonly metaService: MetaService,
        private readonly pageBusyService: PageBusyService,
        private readonly trackingService: TrackingService
    ) {}

    async ngOnInit() {
        const data: PageResponse = this.activatedRoute.snapshot.data.content;

        await this.createComponent(data);

        this.updateFromData(data);
        this.pageBusyService.isPageBusy$.next(true);
    }

    private async createComponent(data: PageResponse) {
        this.viewContainerRef.clear();

        const { LazyComponent: pageComponent } =
            (await getLazyPageComponent(data.type)) ?? fallbackComponent;

        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory<PageComponentData>(
                pageComponent
            );

        const { instance } =
            this.viewContainerRef.createComponent(componentFactory);

        instance.data = data;
    }

    private setMetaData(data: PageResponse) {
        this.metaService.setCanonical(data.metaCanonical?.url);
        this.metaService.setDescription(data.metaDescription);
        this.metaService.setImpactHook(data.id);
        this.metaService.setKeywords(data.metaKeywords);
        this.metaService.setNoIndex(!!data.excludeFromRobots);
        this.metaService.setTitle(data.metaTitle);

        this.metaService.setOpenGraph({
            title: data.metaTitle ?? '',
            image: data.soMeImage
                ? {
                      url: ImageUrl(data.soMeImage, {
                          width: 1200,
                          height: 630,
                          mode: 'crop',
                      }),
                      altText: data.soMeImage.alt,
                      width: '1200',
                      height: '630',
                  }
                : undefined,
            type: 'website',
        });
    }

    private updateFromData(data: PageResponse) {
        this.headerService.setHeaderTransparency(data.transparentSiteHeader);
        this.setMetaData(data);

        const allowTracking = !disallowTrackingTypes.includes(data.type);
        if (allowTracking) {
            this.trackingService.trackVirtualPageview(data.type);
        }
    }
}
