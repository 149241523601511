import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Cms } from '@impact/data';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../navigation.service';
import { MegaNavigationService } from '../mega-navigation/mega-navigation.service';

@Component({
    selector: 'app-main-navigation',
    template: `
        <nav>
            <ul class="main-navigation__list" role="navigation" aria-label="main navigation">
                <ng-container *ngFor="let item of (mainNavigation$ | async)">
                    <li class="main-navigation__list-item">
                        <a class="main-navigation__list-item-link"
                            (keydown.enter)="mainNavigationLinkClick(item, $event)"
                            [routerLinkActive]="'is-route-active'"
                            [ngClass]="{
                                'is-megamenu-active': activeMegamenuItem === item?.id,
                                'is-clicked-active': clickedActiveItemUrl === item?.url,
                                'is-route-active': activeRouteItem === item?.url
                            }"
                            [attr.aria-current]="activeMegamenuItem === item.id || activeRouteItem === item.url ? 'page' : undefined"
                            [routerLink]="item?.pageLink?.cleanUrl || item.url"
                            [queryParams]="item?.pageLink?.urlParams">

                            <span class="site-main-nav__link-text-wrapper" (click)="mainNavigationLinkClick(item, $event)">
                                <span class="site-main-nav__link-text" (click)="mainNavigationLinkClick(item, $event)">{{ item.name }}</span>
                            </span>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </nav>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavigationComponent implements OnInit, OnDestroy {
    activeMegamenuItem: any; // Keeps state of which megamenu item is active
    activeRouteItem: string | undefined; // Keeps state of which route item is active
    clickedActiveItemUrl: string;
    currentRoute: string; // Holds current route for switching between active states
    megaNavigationIsActive: boolean;

    mainNavigation$ = this.navigationService.mainNavigation$.pipe(map(items => items.filter(i => !i.hideInMenu && i.includeInNavigation)));

    private unsubscribe = new Subject<void>();

    constructor(
        private megaNavigationService: MegaNavigationService,
        private navigationService: NavigationService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        // Close MegaNavigation on routing
        this.navigationService.currentUrl$.subscribe((route) => {
            this.currentRoute = route;
            this.activeRouteItem = this.getRouteTopLevel(route);
            this.megaNavigationService.showMegaNavigation(false);
        });

        // Detect when MegaNavigation is hidden, to reset the states of the navigation items
        this.megaNavigationService.showMegaNavigation$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            if (!data.isActive) {
                this.megaNavigationIsActive = false;
                this.activeMegamenuItem = undefined;
                this.clickedActiveItemUrl = '';

                if (this.currentRoute) {
                    this.activeRouteItem = this.getRouteTopLevel(this.currentRoute);
                }
            } else {
                this.megaNavigationIsActive = true;
            }
            this.cdr.markForCheck();
        });
    }

    // Main navigation click handler
    mainNavigationLinkClick(item: Cms.NavigationDto, $event: any) {
        if (this.isMegaNavigationPage(item)) {
            // Prevent routing to be able to display MegaNavigation
            $event.preventDefault();
            $event.stopPropagation();
            this.clickedActiveItemUrl = item.url;

            if (this.megaNavigationIsActive) {
                // If user clicks the currently open MegaNavigation item, we close it
                if (this.activeMegamenuItem === item.id) {
                    this.activeRouteItem = this.getRouteTopLevel(this.currentRoute);
                    this.megaNavigationService.showMegaNavigation(false);
                } else {
                    // Change the current contents of the MegaNavigation
                    this.activeMegamenuItem = item.id;
                    this.megaNavigationService.showMegaNavigation(true, item);
                }
            } else {
                // Activate MegaNavigation
                this.activeRouteItem = this.getRouteTopLevel(this.currentRoute);
                this.activeMegamenuItem = item.id;
                this.megaNavigationService.showMegaNavigation(true, item);
            }
        } else {
            // Open link / go to page. MegaNavigation will close when routing
            // Set activeRouteItem if route is the same as currentRoute
            this.activeRouteItem = this.getRouteTopLevel(this.currentRoute);
            this.activeMegamenuItem = undefined;
            this.megaNavigationService.showMegaNavigation(false, undefined);
        }

        this.cdr.markForCheck();
    }

    // Check wether item is eligible for showing mega navigation
    isMegaNavigationPage(item: Cms.NavigationDto): boolean {
        if (!item.children?.length) {
            return false;
        }

        return true;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private getRouteTopLevel(url: string) {
        if (!url) {
            return;
        }

        const cleanUrl: string = url.split('?')[0];

        return cleanUrl.split('/').slice(0, 2).join('/');
    }

}
