import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Cms } from '@impact/data';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';

import * as icons from '../../../icons';
import { MetaMenuTypes } from '../navigation-types';
import { NavigationService } from '../navigation.service';
import { SettingsService } from '../../core/settings.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-mobile-meta-list',
    template: `
        <ul class="mobile-menu__list mobile-menu__list--secondary" [ngSwitch]="isB2B$ | async">
            <!-- B2B -->
            <ng-container *ngSwitchCase="true">
                <li *ngFor="let metaLink of metaLinksB2B"
                    [ngSwitch]="metaLink?.isB2B2CLink"
                    class="mobile-menu-item"
                    [ngClass]="{ 'mobile-menu-item--b2b2c': metaLink?.isB2B2CLink }"
                >
                    <ng-container *ngSwitchCase="false">
                        <ng-container *ngTemplateOutlet="metaLinkTmpl; context: { $implicit: metaLink }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="true">
                        <ng-container *ngTemplateOutlet="b2B2CMetaLink; context: { $implicit: metaLink }"></ng-container>
                    </ng-container>
                </li>
            </ng-container>
            <!-- B2C -->
            <ng-container *ngSwitchCase="false">
                <li *ngFor="let metaLink of metaLinksB2C"
                    [ngSwitch]="metaLink?.isB2B2CLink"
                    class="mobile-menu-item"
                    [ngClass]="{ 'mobile-menu-item--b2b2c': metaLink?.isB2B2CLink }"
                >
                    <ng-container *ngSwitchCase="false">
                        <ng-container *ngTemplateOutlet="metaLinkTmpl; context: { $implicit: metaLink }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="true">
                        <ng-container *ngTemplateOutlet="b2B2CMetaLink; context: { $implicit: metaLink }"></ng-container>
                    </ng-container>
                </li>
            </ng-container>
        </ul>

        <ng-template #metaLinkTmpl let-metaLink>
            <a appInterceptLinks *ngIf="metaLink.link && metaLink.link.url" [href]="metaLink.link.cleanUrl" (click)="overlayRef.close()">
                <span class="mobile-menu-item__text">{{ metaLink.linkText }}</span>
                <ng-container [ngSwitch]="!!metaLink.linkType">
                    <ng-container *ngSwitchCase="true">
                        <ng-container *ngTemplateOutlet="metaLinkIcon; context: { $implicit: metaLink.linkType }"></ng-container>
                    </ng-container>
                    <img *ngSwitchDefault [src]="metaLink.linkIconSolid?.src | resolveMediaUrl" class="mobile-menu-item__icon icon link-icon__solid" />
                </ng-container>
            </a>
        </ng-template>

        <ng-template #b2B2CMetaLink let-b2B2CMetaLink>
            <ng-container [ngSwitch]="isB2B$ | async">
                <ng-container *ngSwitchCase="true">
                    <a
                        [routerLink]="b2B2CMetaLink?.b2cLink?.url"
                        [attr.target]="b2B2CMetaLink?.b2cLink?.target === '' ? null : b2B2CMetaLink?.b2cLink?.target"
                        rel="noopener"
                        (click)="overlayRef.close()"
                    >
                        <span class="mobile-menu-item__text">{{ b2B2CMetaLink?.b2cLinkText }}</span>
                        <ng-container *ngTemplateOutlet="b2B2CMetaLinkIcon"></ng-container>
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                    <a
                        [routerLink]="b2B2CMetaLink?.b2bLink?.url"
                        [attr.target]="b2B2CMetaLink?.b2bLink?.target === '' ? null : b2B2CMetaLink?.b2bLink?.target"
                        rel="noopener"
                        (click)="overlayRef.close()"
                    >
                        <span class="mobile-menu-item__text">{{ b2B2CMetaLink?.b2bLinkText }}</span>
                        <ng-container *ngTemplateOutlet="b2B2CMetaLinkIcon"></ng-container>
                    </a>
                </ng-container>
            </ng-container>
        </ng-template>

        <!-- Meta Link icon template -->
        <ng-template #metaLinkIcon let-linkType>
            <ng-container [ngSwitch]="linkType" *ngIf="linkType">
                <i class="mobile-menu-item__icon" *ngSwitchCase="metaMenuTypes.DEALERSHIPS" [innerHTML]="icons.pinIcon | safe: 'html'"></i>
                <i class="mobile-menu-item__icon" *ngSwitchCase="metaMenuTypes.AUTOSHOPS" [innerHTML]="icons.wrenchIcon | safe: 'html'"></i>
                <i class="mobile-menu-item__icon" *ngSwitchCase="metaMenuTypes.BOOK_MEETING" [innerHTML]="icons.clockIcon | safe: 'html'"></i>
            </ng-container>
        </ng-template>

        <!-- B2B2C meta Link icon template -->
        <ng-template #b2B2CMetaLinkIcon>
            <i class="mobile-menu-item__icon" [innerHTML]="icons.arrowRightIcon | safe: 'html'"></i>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMetaListComponent implements OnInit, OnDestroy {
    metaLinksB2C: (Cms.MetaLink | Cms.MetalinkB2B)[] = [];
    metaLinksB2B: (Cms.MetaLink | Cms.MetalinkB2B)[] = [];
    icons = icons;
    metaMenuTypes = MetaMenuTypes;
    isB2B$ = this.navigationService.isB2B$;
    private unsubscribe = new Subject<void>();

    constructor(
        public overlayRef: ImpactOverlayRef,
        private navigationService: NavigationService,
        private settingsService: SettingsService) {}

    ngOnInit(): void {
        this.settingsService.getMetaLinks().pipe(takeUntil(this.unsubscribe)).subscribe((metaLinksData) => {
            const { metaLinksB2C, metaLinksB2B } = metaLinksData;
            this.metaLinksB2C = metaLinksB2C.filter((metaLink: Cms.MetaLink | Cms.MetalinkB2B) => metaLink.visibleOnMobile);
            this.metaLinksB2B = metaLinksB2B.filter((metaLink: Cms.MetaLink | Cms.MetalinkB2B) => metaLink.visibleOnMobile);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
